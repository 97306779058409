@if (formReady()) {
    <!-- Geometry -->
    @if (hasGeometryQuestion()) {
        <div class="px-10 py-5">
            <p class="relative text-sm text-text-primary font-semibold leading-none flex items-center gap-2">
                {{ geometryTitle() }}{{ geometryRequired() ? ' *' : null }}
                @if (geometryHelp()) {
                    <i class="fa-solid fa-circle-question text-text-secondary hover:cursor-pointer" [pTooltip]="geometryHelp()" tooltipPosition="top"></i>
                }
            </p>
            @if (geometryDescription()) {
                <div class="flex items-center justify-start gap-2 pt-2">
                    @if (geometryDescription()) {
                        <p class="text-xs text-text-secondary">{{ geometryDescription() }}</p>
                    }
                </div>
            }
        </div>
        <div class="relative h-72 w-full">
            <app-record-map 
                #recordMap 
                [edit]="true"
                [feature]="feature()"
                [allowedGeometryTypes]="allowedGeometryTypes()"
                [enforceBounds]="enforceBounds()"
                [projectBounds]="projectBounds()"
                [baseStyleName]="mapStyleName()"
                (onStyleChanged)="onMapStyleChanged.emit($event)"
            ></app-record-map>
        </div>
        @if (geometryRequired() && !geometryValid) {
            <div class="px-10 pt-5">
                <p class="text-xs text-danger leading-none">Geometry required</p>
            </div>
        }
    }
    <!-- Form -->
    <form [formGroup]="form()" class="p-10 flex flex-col gap-6">
        @for (question of questions(); track question) {
            @if (!isHidden(question)) {
                <app-form-question
                    [form]="form()"
                    [question]="question"
                    [isEdit]="isEdit()"
                ></app-form-question>
            }
        }
    </form>
} 
@else {
    <div class="h-60 w-full pt-4">
        <div class="flex items-center justify-center">
            <p-progressSpinner styleClass="h-8 w-8" class="h-8 w-8"></p-progressSpinner>
        </div>
    </div>
}