import { Component, computed, EventEmitter, inject, input, Output } from '@angular/core';
import { RecordLog } from './record-activity.component';
import { VerificationStateMap } from '../record-detail.component';
import { CommonModule, DatePipe } from '@angular/common';
import { DateToNowPipe } from 'src/app/shared/pipes/date-to-now.pipe';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';

@Component({
    selector: 'app-record-log',
    templateUrl: 'record-log.component.html',
    standalone: true,
    imports: [
        CommonModule,
        DateToNowPipe,
        AvatarModule,
        ButtonModule
    ]
})

export class RecordLogComponent {

    datePipe = inject(DatePipe);

    log = input<RecordLog>();
    verificationStateMap = input<VerificationStateMap>();

    @Output() reviewChanges: EventEmitter<RecordLog> = new EventEmitter();

    operation = computed(() => {
        const log = this.log();
        return log.operation;
    });

    createdAt = computed(() => {
        const log = this.log();
        return log.createdAt;
    });

    state = computed(() => {
        const log = this.log();
        const operation = this.operation();
        const diff = log.diff;
        
        if (operation === 'verification' && diff.length > 0) {
            const value = diff[0].value;
            const state = this.verificationStateMap()[value];
            return state ?? null;
        } else {
            return null;
        }
    });

    userName = computed(() => {
        const log = this.log();
        return log.user?.displayName ?? 'Unknown User';
    });
    
    userImage = computed(() => {
        const log = this.log();
        return log.user?.imageUrl;
    });

    operationString = computed<string>(() => {
        const operation = this.operation();
        const state = this.state();
        switch (operation) {
            case 'create':
                return 'created this record on';
            case 'update':
                return 'updated this record on';
            case 'verification':
                return !!state ? 'changed the status from' : 'changed the status';
        }
    });

    dateString = computed<string>(() => {
        const createdAt = this.createdAt();
        return this.datePipe.transform(createdAt, "d MMMM y 'at' hh:mm a");
    });

    stateString = computed(() => {
        const state = this.state();
        return state?.name;
    });

    badgeColor = computed(() => {
        const state = this.state();
        return state?.color;
    });

    badgeBackgroundColor = computed(() => {
        const state = this.state();
        return state?.name ? `${state.color}1A` : null;
    });
}