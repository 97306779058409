<div class="relative h-full w-full flex flex-col overflow-hidden">
    <div class="grow overflow-y-auto">
        <app-record-form 
            #recordForm
            [projectId]="projectId()"
            [surveyId]="surveyId()"
        ></app-record-form>
    </div>
    <div class="flex-none flex items-center justify-end gap-4 p-4">
        <p-button size="large" label="Cancel" [outlined]="true" (click)="cancel()"></p-button>
        <p-button size="large" label="Save" (click)="saveCheck()"></p-button>
    </div>
    @if (showHasErrors()) {
        <div class="absolute inset-0 z-10 flex items-center justify-center bg-white bg-opacity-50">
            <div class="bg-white p-10">
                <p class="text-lg font-semibold text-center mb-2">Complete All Required Fields</p>
                <p class="text-base text-center mb-4">Please fill in all mandatory fields to save your data.<br>Look for red text below fields to identify missing information.</p>
                <div class="flex items-center justify-center gap-4">
                    <p-button size="large" (click)="showHasErrors.set(false)" label="Ok"></p-button>
                </div>
            </div>
        </div>
    }
</div>