import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, computed, inject, signal } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TwicPicPipe } from 'src/app/shared/pipes/twicpic.pipe';

interface Image {
    url: string; 
    name: string;
}

export interface ImageModalProps {
    images: Image[];
    index?: number;
}

@Component({
    selector: 'app-image-modal',
    templateUrl: 'image-modal.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        ButtonModule,
        CommonModule
    ],
    providers: [
        TwicPicPipe
    ]
})
export class ImageModalComponent implements OnInit {

    dialogConfig = inject(DynamicDialogConfig);
    twicPicPipe = inject(TwicPicPipe);

    public images = signal<Image[]>([]);
    public index = signal<number>(0);

    public currentImage = computed<Image>(() => {
        return this.images()[this.index()];
    });

    public src = computed(() => {
        const image = this.currentImage();
        const url = image.url;
        if (url.startsWith('https://coreo.s3')) {
            return this.twicPicPipe.transform(url, 'resize=1080');
        } else {
            return url;
        }
    });

    public first = computed<boolean>(() => {
        return this.index() === 0;
    });

    public last = computed<boolean>(() => {
        return this.index() === (this.images().length - 1);
    });

    public multiple = computed<boolean>(() => {
        return this.images().length > 1;
    });

    ngOnInit() {
        const data = this.dialogConfig.data as ImageModalProps;
        this.images.set(data.images ?? []);
        this.index.set(data.index ?? 0);
    }

    public next() {
        if (this.last()) {
            return;
        }
        this.index.update(i => i + 1);
    }

    public previous() {
        if (this.first()) {
            return;
        }
        this.index.update(i => i - 1);
    }

    downloadImage() {
        const { url, name } = this.currentImage();
        fetch(url)
            .then(response => response.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                let link = document.createElement("a");
                link.href = url;
                link.download = name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    }
}