<div class="w-48 overflow-hidden">
    <div class="sort-handle relative w-48 aspect-square border border-grey-30 hover:cursor-grab">
        @if (mediaType() === 'image') {
            <img [src]="src()" alt="" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-48 max-h-48">
        }
        @else if (mediaType() === 'audio') {
            <audio [controls]="true" class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-48 max-h-48 px-2">
                <source [src]="src()" [type]="item().type">
                Your browser does not support this audio tag
            </audio>
        }
        <button (click)="edit($event)" class="absolute top-1 left-1 h-5 w-5 rounded-[4px] bg-white bg-opacity-60 p-1 flex items-center justify-center">
            <i class="fa-solid fa-pen text-primary text-sm"></i>
        </button>
        <button (click)="deleteMediaItemCheck($event)" class="absolute top-1 right-1 h-5 w-5 rounded-[4px] bg-white bg-opacity-60 p-1 flex items-center justify-center">
            <i class="fa-solid fa-trash-can text-danger text-sm"></i>
        </button>
    </div>
    <div class="w-full p-2">
        <p class="text-center text-sm break-words">{{ item().name }}</p>
    </div>
</div>