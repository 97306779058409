import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { getProjectForms, getProjectAttributes, getProjectStates, getProjectSlug, getRecordsSelectionSource, getProjectVerification, getRecordsPaginationSize, getRecordsPaginationPage, getRecordsOrder, getProjectOrgSlug, getRecordsSelectedIds, getProjectRole, getRecordsFormId, getProjectForm } from '../../../store/selectors';
import { urlToImageProxy } from '../../../helpers/utils';

export const ProjectRecordsTableComponent = {
    template: '<div id="project-records-table"></div>',
    bindings: {
        columns: '<',
        onUpdateState: '&'
    },
    controllerAs: 'ctrl',
    controller: class ProjectRecordsTableComponent {

        static $inject = ['$scope', '$timeout', '$ngRedux', '$compile', '$rootScope', 'CoreoAPI', 'RecordsActions', 'apiHostname', 'Tabulator'];

        formColors = new Map();
        formNames = new Map();

        constructor($scope, $timeout, $ngRedux, $compile, $rootScope, CoreoAPI, RecordsActions, apiHostname, Tabulator) {
            this.$scope = $scope;
            this.$timeout = $timeout;
            this.$compile = $compile;
            this.CoreoAPI = CoreoAPI;
            this.surveyNameLookup = {};
            this.stateLookup = {};
            this.$ngRedux = $ngRedux;
            this.$rootScope = $rootScope;
            this.RecordsActions = RecordsActions;
            this.apiHostname = apiHostname;
            this.Tabulator = Tabulator;

            // Static initalization
            const initialState = $ngRedux.getState();
            const forms = getProjectForms(initialState);
            this.attributes = getProjectAttributes(initialState);
            this.projectSlug = getProjectSlug(initialState);
            this.orgSlug = getProjectOrgSlug(initialState);
            this.projectRole = getProjectRole(initialState);
            this.stateLookup = _.reduce(getProjectStates(initialState), function (acc, state) {
                acc[state.stateId] = state;
                return acc;
            }, {});
            this.verification = getProjectVerification(initialState);
            this.parentRow;
            this.selectedRow;
            this.rowIndex;

            // Columns Mapping
            this.columnDefinitions = {
                id: {
                    title: "ID",
                    field: "id",
                    // width: '100',
                    formatter: (cell) => {
                        var link = document.createElement('a');
                        const data = cell.getData();
                        const id = data.id;
                        return this.renderRecordLink(id);
                    },
                    cellClick: (e) => {
                        // Do not propagate event if link was clicked
                        if (e.target && ['A', 'I'].indexOf(e.target.tagName) !== -1) {
                            e.stopPropagation();
                        }
                    }
                },
                userId: {
                    title: "User",
                    field: "userId",
                    hozAlign: "left",
                    headerSort: false,
                    // width: 200,
                    formatter: (cell) => {
                        var data = cell.getData();
                        return data && data.user && data.user.displayName || '-';
                    }
                },
                createdAt: {
                    title: "Created",
                    field: "createdAt",
                    hozAlign: "left",
                    // width: 200,
                    formatter: (cell) => {
                        return format(parseISO(cell.getValue()), 'dd/MM/yyyy HH:mm:ss a');
                    }
                },
                state: {
                    title: "Status",
                    field: "state",
                    hozAlign: "left",
                    formatter: (cell) => {
                        const record = cell.getData();
                        const stateId = cell.getValue();
                        const state = this.stateLookup[stateId];
                        const scope = this.$scope.$new(true);
                        scope.updateState = (state) => {
                            if (!record) {
                                return;
                            }
                            this.$ngRedux.dispatch(this.RecordsActions.recordsUpdateRecordState(record.id, state)).then(() => {
                                this.$timeout(() => this.table.setData(), 0);
                            })
                        }

                        scope.id;
                        scope.state = state && state.stateId;
                        const el = angular.element(this.$compile(`<project-record-state class="h-5 hover:shadow-sm border hover:border-grey-20" state="state" on-update="updateState(state)">`)(scope));

                        // This prevents the click of the record-state changing the table selection
                        el[0].addEventListener('click', (ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                        });
                        return el[0];
                    }
                }
            };

            $scope.$on('$destroy', $ngRedux.connect((state) => {
                const selectedIds = getRecordsSelectedIds(state);
                const selectionSource = getRecordsSelectionSource(state);

                // If selection has changed from the map, reload the data
                if (selectionSource && (selectionSource !== 'table') && selectedIds !== this.selectedIds) {
                    this.$timeout(() => this.table.setData(), 0);
                }

                return {
                    selectedIds,
                    selectionSource
                };
            }, RecordsActions)(this));

            $scope.$on('$records:refresh', () => {
                this.table.setData();
            });
        }

        getColumnDefinition(c) {
            const [fieldId] = c;
            if (this.columnDefinitions.hasOwnProperty(fieldId)) {
                return this.columnDefinitions[fieldId];
            } else {
                return this.createCustomColumn(c);
            }
        }

        renderRecordLink(id, title) {
            const el = Object.assign(document.createElement('a'), {
                href: `/${this.orgSlug}/${this.projectSlug}/records/${id}`,
                target: '_blank',
                className: 'record-id'
            });
            const icon = Object.assign(document.createElement('i'), {
                className: 'fas fa-external-link-alt'
            });

            const span = Object.assign(document.createElement('span'), {
                innerText: title || id
            });

            el.appendChild(span);
            el.appendChild(icon);

            return el;
        }

        renderAttachment(images, path, label) {
            const scope = this.$scope.$new(true);
            scope.images = images;
            scope.path = path;
            scope.label = label;
            const compiled = this.$compile(angular.element('<app-image-modal-viewer [images]="images" [path]="path" [label]="label"></app-image-modal-viewer'))(scope);
            const container = compiled[0];
            return container;
        }

        renderLegacyAttachment(mode, url, path, label) {
            if (mode === 'audio') {
                const el = angular.element(`<a target="_blank">Play Audio</a>`).attr('href', url);
                return el[0];
            }
            return this.renderAttachment([url], path, label);
        }

        createCustomColumn(c) {
            const [attributeId, active] = c;
            const attribute = this.attributes.find(a => +a.id === +attributeId);

            const unsortableQuestionTypes = ['association', 'child'];

            let title = attribute.label;
            if (attribute.questionType === 'child') {
                title = this.formNames.get(attribute.surveyId);
            }

            return {
                title,
                field: ["data", attributeId].join('.'),
                width: 200,
                visible: active,
                headerSort: !unsortableQuestionTypes.includes(attribute.questionType),
                formatter: (cell) => {
                    const cellData = cell.getData();
                    if (attribute.questionType === 'association' || attribute.questionType === 'child') {
                        const association = cellData.associates.find(a => a.association.id === attribute.id);
                        if (association && association.record) {
                            return this.renderRecordLink(association.record.id, association.record.title);
                        }
                        return '';
                    }

                    // Handling attachments
                    if (attribute.type === 'attachment') {
                        const attributeAttachments = cellData.attachments.filter(a => a.attributeId === attribute.id);
                        if (attributeAttachments.length === 0) {
                            return '';
                        }
                        const allUrls = attributeAttachments.map(a => a.url);
                        return this.renderAttachment(allUrls, attribute.path, attribute.label);
                        // const container = angular.element('<div class="flex gap-2"></div>');
                        // const allUrls = attributeAttachments.map(a => a.url);
                        // for (const a of attributeAttachments) {
                        //     container[0].appendChild(this.renderAttachment(null, a.url, allUrls));
                        // }
                        // return container[0];
                    }

                    // Handling of legacy media 
                    if (attribute.type === 'media') {
                        const attachment = cellData.attachments.find(a => a.attributeId === attribute.id);
                        if (typeof attachment !== 'undefined') {
                            return this.renderLegacyAttachment(attribute.questionType, attachment.url,  attribute.path, attribute.label);
                        }
                        return '';
                    }

                    const data = cellData.data[attribute.path];

                    if (typeof data === 'undefined') {
                        return '';
                    }

                    switch (attribute.type) {
                        case 'geometryquery':
                        case 'multiselect': {
                            if (Array.isArray(data)) {
                                const scope = this.$scope.$new(true);
                                scope.collectionId = attribute.collectionId;
                                scope.data = data;
                                const el = angular.element(this.$compile(`<item-value collection-id="collectionId" item-keys="data"></item-value>`)(scope))
                                return el[0];
                            }
                        }
                        case 'select': {
                            const scope = this.$scope.$new(true);
                            scope.collectionId = attribute.collectionId;
                            scope.data = data;
                            const el = angular.element(this.$compile(`<item-value collection-id="collectionId" item-keys="data"></item-value>`)(scope));
                            return el[0];
                        }
                        case 'date': {
                            try {
                                return format(parseISO(data), 'dd/MM/yyyy');
                            } catch (e) {
                                console.warn(data, e);
                                return '';
                            }
                        }
                        case 'datetime': {
                            try {
                                return format(parseISO(data), 'dd/MM/yyyy HH:mm:ss a');
                            } catch (e) {
                                console.warn(data, e);
                                return '';
                            }
                        }
                        case 'boolean': {
                            if (typeof data === 'boolean') {
                                return data ? 'Yes' : 'No';
                            }
                            return data;
                        }
                        default: {
                            return data;
                        }
                    }
                },
                cellClick: (e) => {
                    // Do not propagate event if link was clicked
                    if (e.target && ['A', 'I'].indexOf(e.target.tagName) !== -1) {
                        e.stopPropagation();
                    }
                }
            }
        }

        createTable() {
            const state = this.$ngRedux.getState();
            const paginationSize = getRecordsPaginationSize(state);
            const paginationPage = getRecordsPaginationPage(state);
            const order = getRecordsOrder(state);

            for (const form of getProjectForms(state)) {
                this.formColors.set(form.id, form.style?.color ?? '#fff');
                this.formNames.set(form.id, form.name);
            }

            const initialSortDir = order.indexOf('reverse:') === 0 ? 'desc' : 'asc';
            const initialSortColumn = order.indexOf(':') === -1 ? order : order.split(':')[1];
            const columns = this.columns.map(c => this.getColumnDefinition(c));

            // If we need a form column
            const formId = getRecordsFormId(state);
            if (formId === null) {
                columns.splice(1, 0, {
                    title: 'Form',
                    formatter: cell => {
                        const surveyId = cell.getData().surveyId;

                        const div = document.createElement('div');
                        div.classList.add('flex', 'items-center', 'overflow-hidden');
                        const dot = document.createElement('span');
                        const name = document.createElement('span');

                        dot.classList.add('project-records-table-form-color');
                        dot.style.backgroundColor = this.formColors.get(surveyId);
                        name.textContent = this.formNames.get(surveyId);
                        div.appendChild(dot);
                        div.appendChild(name);
                        return div;
                    }
                });
            }

            this.table = this.Tabulator.createTable("#project-records-table", {
                height: '100%',
                layout: formId === null ? "fitColumns" : "fitDataFill",
                selectable: true,
                selectableCheck: () => this.selectionSource !== 'map',
                selectableRangeMode: "click",
                placeholder: "No matching records",
                dataLoaderLoading: "<i class='spinner'></i>",
                movableColumns: true,
                rowFormatter: function (row) {
                    if (row.getData().deletedAt) {
                        row.getElement().style.backgroundColor = "#de5b5b";
                    }
                },
                columns,
                initialSort: [
                    { column: initialSortColumn, dir: initialSortDir }
                ],
                columnHeaderSortMulti: false,
                paginationSize,
                paginationSizeSelector: [10, 25, 50, 100],
                ajaxURL: this.apiHostname,
                ajaxRequestFunc: this.query.bind(this),
                pagination: true,
                paginationMode: "remote",
                paginationInitialPage: paginationPage,
                sortMode: "remote"
            });

            this.table.on('rowClick', () => {
                if (this.selectionSource === 'map') {
                    return;
                }

                const data = this.table.getSelectedData();
                this.$timeout(() => {
                    this.selectRecord(data.map(d => d.id), 'table', false);
                }, 0);

            });

            this.table.on('rowMouseOver', (_e, row) => {
                const { id } = row.getData();
                this.$rootScope.$broadcast('$records:tableMouseOver', id);
            });

            this.table.on('rowMouseOut', (_e, row) => {
                const { id } = row.getData();
                this.$rootScope.$broadcast('$records:tableMouseOut', id);
            });
        }

        query(_url, _config, params) {
            const { size, sort, page } = params;

            let order = 'reverse:createdAt';

            if (sort && sort.length > 0) {
                const sorter = sort[0];
                const id = sorter.field.split('.').pop();

                if (['id', 'userId', 'createdAt', 'status'].includes(id)) {
                    order = id;
                } else {
                    const attribute = this.attributes.find(a => a.id === +id);
                    if (attribute && attribute.path) {
                        order = `data.${attribute.path}`
                    }
                }
                order = (sorter.dir === 'desc' ? 'reverse:' : '') + order;
            }

            return this.loadRecords({
                pagination: {
                    page: page ?? 1,
                    size: size ?? 10
                },
                order
            }).then(result => ({
                data: angular.copy(result.data),
                last_page: result.lastPage
            }));
        }

        $onInit() {
            this.createTable();
        }

        $onChanges(changes) {
            if (changes.hasOwnProperty('columns') && this.table) {
                this.table.destroy();
                this.createTable();
            }
        }
    }
}
