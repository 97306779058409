import { Component, forwardRef, inject, signal, viewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RecordFormComponent } from 'src/app/features/records/record-form/record-form.component';
import { NewChildRecord, RecordUpdateData } from '../../records.service';
import { Geometry } from 'geojson';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'app-child-form-modal',
    templateUrl: 'child-form-modal.component.html',
    standalone: true,
    imports: [
        forwardRef(() => RecordFormComponent),
        ButtonModule,
        TooltipModule
    ]
})

export class ChildFormModalComponent {

    private dialogConfig = inject(DynamicDialogConfig);
    private dialogRef = inject(DynamicDialogRef);

    recordFormComponent = viewChild<RecordFormComponent>('recordForm');

    projectId = signal<number | null>(null);
    surveyId = signal<number | null>(null);
    attributeId = signal<number | null>(null);

    showHasErrors = signal(false);

    constructor() { 
        this.projectId.set(this.dialogConfig.data['projectId']);
        this.surveyId.set(this.dialogConfig.data['surveyId']);
        this.attributeId.set(this.dialogConfig.data['attributeId']);
    }
    
    public saveCheck() {
        const isValid = this.recordFormComponent().recordUpdateDataValid && this.recordFormComponent().geometryValid;

        if (isValid) {
            this.save();
        } else {
            this.showHasErrors.set(true);
        }
    }
                    
    private save() {
        const recordUpdateData: RecordUpdateData = this.recordFormComponent().formRecordUpdateData;
        const geometry: Geometry = this.recordFormComponent().updatedGeometry;

        const data: NewChildRecord = {
            projectId: this.projectId(),
            surveyId: this.surveyId(),
            geometry,
            data: recordUpdateData.data,
            associates: recordUpdateData.newAssociates,
            childRecords: recordUpdateData.childRecords,
            attachments: recordUpdateData.newAttachments,
            attributeId: this.attributeId()
        };

        this.dialogRef.close(data);
    }

    public cancel() {
        this.dialogRef.close();
    }
}