<div>
    @if (valid()) {
        <p class="text-success text-xs text-center my-4">Your password is secure and you're all set</p>
    }
    @else {
        <ul class="grid grid-cols-2">
            @for (check of passwordConfig(); track check.key) {
                <li class="h-4 text-[10px] text-text-primary flex items-center"
                [ngClass]="{'text-[#47CD6D]': check.valid, 'text-grey-70': !check.valid}">
                    <i class="fa-solid mr-2" [ngClass]="{'fa-circle text-[4px]': !check.valid, 'fa-check': check.valid}"
                    ></i>{{check.message}}
                </li>
            }
        </ul>
    }
</div>