<div class="relative">
    <ul #list class="flex items-start flex-wrap gap-5 pt-5 px-1">
        @for (item of sortedItems(); track item) {
            <li [attr.data-item]="item.id">
                <app-collection-item-media [item]="item" (click)="clickImage($event, item)"></app-collection-item-media>
            </li>
        }
    </ul>
    @if (mediaLoading()) {
        <div class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
            <p-progressSpinner styleClass="h-10 w-10" class="h-10 w-10"></p-progressSpinner>
        </div>
    }
</div>