import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { CollectionItem, Question } from '../form.types';
import { FormGroup } from '@angular/forms';
import { Observable, map, tap } from 'rxjs';
import { ApiService } from 'src/app/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CollectionInfoModalComponent } from '../collection-info-modal/collection-info-modal.component';
import { CommonModule } from '@angular/common';
import { AvatarModule } from 'primeng/avatar';
import { MultipleChoiceItemComponent } from './multiple-choice-item.component';

@Component({
    selector: 'app-multiple-choice',
    templateUrl: 'multiple-choice.component.html',
    standalone: true,
    imports: [
        CommonModule,
        AvatarModule,
        MultipleChoiceItemComponent
    ]
})

export class MultipleChoiceComponent implements OnInit, OnDestroy {

    question = input<Question>();
    form = input<FormGroup>();

    public multiselect: boolean = false;
    public collection$: Observable<CollectionItem[]>;
    public hasImages: boolean = false;
    public showTooltip: boolean = false;

    private dialogRef: DynamicDialogRef | undefined;

    constructor(
        private apiService: ApiService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        if (this.question().type === 'multiselect') {
            this.multiselect = true;
        }
        this.showTooltip = this.question().config.collectionTooltip;
        this.getCollection();
    }

    ngOnDestroy() {
        if (this.dialogRef) {
            this.dialogRef.close();
        }
    }

    private getCollection() {
        const collectionsFragment = `fragment collectionFields on Collection{
            items{
                id
                collectionId
                key
                value
                data
                sort
                mediaItems{
                    type
                    url
                }
            }
        }`;

        const query = `query getCoreoCollection{
            project(id: ${this.question().projectId}){
                collections(where: { id: ${this.question().collectionId} }){ ...collectionFields }
                parent{
                    collections(where: { id: ${this.question().collectionId} }){ ...collectionFields }
                }
            }
        }${collectionsFragment}`;

        this.collection$ = this.apiService.graphql<{ project: { collections: { items: CollectionItem[] }[]; parent: { collections: { items: CollectionItem[]; }[] } } }>(query).pipe(
            map(res => {
                const collection = res.project.collections?.[0] ?? res.project.parent?.collections?.[0];
                return collection.items;
            }),
            tap(items => {
                this.hasImages = items.reduce((acc, item) => {
                    if (item.mediaItems.length > 0) {
                        acc.push(item.mediaItems.filter(mi => mi.type && mi.type.startsWith('image')))
                    }
                    return acc;
                  }, []).length > 0;
            })
        );
    }

    public isSelected(item: CollectionItem): boolean {
        const value = this.form().controls[this.question().path].value;

        if (this.multiselect) {
            return !!(value as string[]).find(i => i === item.key);
        } else {
            return (value as string) === item.key;
        }
    }

    public handleSelect(item: CollectionItem) {
        // console.log('handleSelect', item);
        
        if (this.multiselect) {
            const values: string[] = this.form().controls[this.question().path].value;
            const isSelected = !!values.find(i => i=== item.key);
            let newValues: string[];
            if (isSelected) {
                newValues = values.filter(i => i !== item.key);
            } else {
                newValues = [...values, item.key];
            }
            this.form().controls[this.question().path].setValue(newValues);
        } else {
            if (this.isSelected(item)) {
                this.form().controls[this.question().path].setValue(null);
            } else {
                this.form().controls[this.question().path].setValue(item.key);
            }
        }
        this.form().markAsDirty();
    }

    public openCollectionInfoModal(itemKey: string) {
        this.dialogRef = this.dialogService.open(CollectionInfoModalComponent, {
            data: {
                projectId: this.question().projectId,
                collectionId: this.question().collectionId,
                itemKey 
            },
            header: 'Info',
            width: '75vw',
            height: '75vh',
            modal: true,
            breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
            },
        });
    }
}