import { computed, inject, Injectable, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Geometry } from 'geojson';
import { catchError, map, of, take, takeLast, zip } from 'rxjs';
import { ApiService } from 'src/app/core';
import { z } from 'zod';

class AttributeQuestion<T> {
    value: T | undefined;
    path: string;
    label: string;
    required: boolean;
    type: 'text' | 'float';
constructor(
    options: {
        value?: T;
        path?: string;
        label?: string;
        required?: boolean;
        type?: 'text' | 'float';
    } = {},
    ) {
        this.value = options.value;
        this.path = options.path;
        this.label = options.label;
        this.required = !!options.required;
        this.type = options.type;
    }
}

const MediaItemSchema = z.object({
    id: z.number(),
    url: z.string(),
    name: z.string(),
    type: z.string(),
    caption: z.string().nullable(),
    sort: z.number(),
    createdAt: z.string(),
    size: z.number()
});

const CollectionItemSchema = z.object({
    id: z.number(),
    key: z.string(),
    value: z.string(),
    geometry: z.any().nullable(),
    data: z.any(),
    mediaItems: MediaItemSchema.array()
});

const CollectionAttributeSchema = z.object({
    id: z.number(),
    label: z.string(),
    type: z.string(),
    path: z.string().nullable(),
    visible: z.boolean()
});

const CollectionSchema = z.object({
    attributes: CollectionAttributeSchema.array(),
    items: CollectionItemSchema.array()
});

const CollectionItemPreviewSchema = z.object({
    title: z.string(),
    data: z.object({
        label: z.string(),
        value: z.any().nullable()
    }).array(),
    mediaItems: MediaItemSchema.array()
});

export type MediaItem = z.infer<typeof MediaItemSchema>;
type CollectionItem = z.infer<typeof CollectionItemSchema>;
type CollectionAttribute = z.infer<typeof CollectionAttributeSchema>;
type Collection = z.infer<typeof CollectionSchema>;
export type CollectionItemPreview = z.infer<typeof CollectionItemPreviewSchema>;

@Injectable()
export class CollectionItemService {

    private apiService = inject(ApiService);

    itemId = signal<number | null>(null);
    collectionId = signal<number | null>(null);
    projectId = signal<number | null>(null);

    loading = signal(false);
    mediaLoading = signal(false);
    collectionName = signal('');
    item = signal<CollectionItem | null>(null);
    geometry = signal<Geometry | null>(null);
    attributes = signal<CollectionAttribute[]>([]);
    attributeQuestions = signal<AttributeQuestion<string | number>[]>([]);

    valueForm = signal<FormGroup | null>(null);
    value = signal<string | null>(null);
    valueError = computed(() => {
        const value = this.value();
        if (!value || value.match(/^ *$/) !== null) {
            return 'Name (Value) is required';
        } else {
            return null;
        }
    });
    valueFormDirty = signal(false);
    attributeForm = signal<FormGroup | null>(null);
    attributeFormDirty = signal(false);

    mediaItems = signal<MediaItem[]>([]);

    preview = computed<CollectionItemPreview>(() => {
        const valueForm = this.valueForm();
        const attributeForm = this.attributeForm();
        const attributes = this.attributes();
        const media = this.mediaItems();
        const data = attributes.filter(a => a.visible).map(a => {
            return {
                label: a.label,
                value: attributeForm.controls[a.path].value
            }
        });

        try {
            return CollectionItemPreviewSchema.parse({
                title: valueForm.controls['value'].value,
                data,
                mediaItems: media
            });
        } catch (err) {
            if (err instanceof z.ZodError) {
              console.warn(err.issues);
            }
        }
    });

    loadItem() {
        this.loading.set(true);

        const query = `query AAGetCollectionItem{
            project(id: ${this.projectId()}){
                collections(where: { id: ${this.collectionId()} }){
                    attributes{
                        id
                        label
                        type
                        path
                        visible
                    }
                    items(where: { id: ${this.itemId()}}){
                        id
                        key
                        value
                        geometry{
                            type
                            coordinates
                        }
                        data
                        mediaItems{
                            id
                            url
                            name
                            type
                            caption
                            sort
                            createdAt
                            size
                        }
                    }
                }
            }
        }`;

        this.apiService.graphql<{ project: { collections: Collection[] } }>(query).pipe(
            takeLast(1),
            catchError((e) => {
                console.warn(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                try {
                    const parsedCollection = CollectionSchema.parse(res.project.collections[0]);
                    const item = parsedCollection.items[0];

                    this.item.set(item);
                    this.attributes.set(parsedCollection.attributes);
                    if (item.mediaItems?.length) {
                        this.mediaItems.set(item.mediaItems);
                    }
                    if (item.geometry) {
                        this.geometry.set(item.geometry);
                    }
                    this.valueForm.set(new FormGroup({
                        value: new FormControl(item.value, Validators.required)
                    }));
                    this.value.set(item.value);

                    this.valueForm().valueChanges.pipe().subscribe(value => {
                        this.value.set(value.value);
                        this.valueFormDirty.set(this.valueForm().dirty);
                    });

                    if (this.attributes().length > 0) {
                        this.buildAttributeForm();
                    }
                } catch (err) {
                    if (err instanceof z.ZodError) {
                      console.warn(err.issues);
                    }
                }
            }
            this.loading.set(false);
        });
    }

    private buildAttributeForm() {
        const item = this.item();
        const attributes = this.attributes();

        const questions: AttributeQuestion<string | number>[] = attributes.map(a => {
            return new AttributeQuestion({
                value: item.data?.[a.path],
                required: false,
                type: a.type as 'text' || 'float',
                path: a.path,
                label: a.label
            });
        });

        this.attributeQuestions.set(questions);

        const group: any = {};
        
        questions.forEach((question) => {
            group[question.path] = new FormControl(question.value || null);
        });

        this.attributeForm.set(new FormGroup(group));
        this.attributeForm().valueChanges.pipe().subscribe(value => {
            this.attributeFormDirty.set(this.attributeForm().dirty);
        });
    }

    updateItem() {
        this.loading.set(true);

        const query = `mutation AAUpdateCollectionItem($input: ItemUpdateInput!){
            updateCollectionItem(input: $input){
                id
            }
        }`;

        const input =  {
            id: this.itemId(),
            value: this.value(),
        }

        if (!!this.attributeForm()) {
            input['data'] = this.attributeForm().getRawValue();
        }

        this.apiService.graphql<{ updateCollectionItem: { id: number } }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.loadItem();
            } else {
                this.loading.set(false);
            }
        });
    }

    deleteItem(id: number) {        
        const query = `mutation AADeleteCollectionItem{
            deleteCollectionItem(input: { id: ${id} })
        }`;

        return this.apiService.graphql<{ deleteCollectionItem: number }>(query);
    }

    createMediaItem(file: File) {
        this.mediaLoading.set(true);

        const query = `mutation AACreateMediaItem($input: ProjectMediaItemCreateInput!){
            createMediaItem(input: $input){
                id
                url
                name
                type
                caption
                sort
                createdAt
                size
            }
        }`;

        /** Ensure item is added to the end of the list */
        const sort = this.mediaItems().length;

        const input =  {
            itemId: this.itemId(),
            size: file.size,
            name: file.name,
            type: file.type,
            projectId: this.projectId(),
            sort
        }

        this.apiService.graphql<{ createMediaItem: MediaItem }>(query, { input }, { file }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const item = MediaItemSchema.parse(res.createMediaItem);
                this.mediaItems.update(items => [...items, item]);
            }
            this.mediaLoading.set(false);
        });
    }

    deleteMediaItem(id: number) {
        this.mediaLoading.set(true);

        const query = `mutation AACreateMediaItem($input: ProjectMediaItemDeleteInput!){
            deleteMediaItem(input: $input)
        }`;

        const input =  {
            id
        }

        this.apiService.graphql<{ deleteMediaItem: number }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.mediaItems.update(items => items.filter(i => i.id !== id));
            }
            this.mediaLoading.set(false);
        });
    }

    updateMediaItem(update: MediaItem) {
        const query = `mutation AAUpdateMediaItem($input: UpdateMediaItemInput!){
            updateMediaItem(input: $input){
                id
                url
                name
                type
                caption
                sort
                createdAt
                size
            }
        }`;

        const input =  {
            id: update.id,
            caption: update.caption,
            sort: update.sort
        }

        return this.apiService.graphql<{ updateMediaItem: MediaItem }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                return of(null);
            }),
            map(r => r.updateMediaItem)
        );
    }

    updateMediaItemsSort(items: MediaItem[]) {
        this.mediaLoading.set(true);

        const updates = items.map(item => {
            return this.updateMediaItem(item);
        });

        zip(updates).subscribe(res => {
            console.log('updateMediaItemsSort', res);
            this.mediaItems.set(res);
            this.mediaLoading.set(false);
        });
    }
}