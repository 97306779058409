import { Component, computed, inject, input, OnInit } from '@angular/core';
import { CollectionItemService, MediaItem } from './collection-item.service';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CollectionItemMediaEditComponent } from './collection-item-media-edit.component';

@Component({
    selector: 'app-collection-item-media',
    templateUrl: 'collection-item-media.component.html',
    standalone: true,
})

export class CollectionItemMediaComponent {

    collectionItemService = inject(CollectionItemService);
    confirmationService = inject(ConfirmationService);
    dialogService = inject(DialogService);

    item = input<MediaItem>();

    mediaType = computed(() => {
        const item = this.item();
        const type = item.type.split('/')[0];
        return type; // 'image' || 'audio'
    });

    src = computed(() => {
        const item = this.item();
        return item.url;
    });

    // private dialogRef: DynamicDialogRef;

    // constructor() { }

    // ngOnInit() { 
    //     console.log('Media', this.item());
        
    // }

    edit($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();

        const header = `${this.mediaType() === 'image' ? 'Image' : 'Sound file'} information`;

        this.dialogService.open(CollectionItemMediaEditComponent, {
            data: {
                item: this.item()
            },
            header,
            width: '402px',
            modal: true,
        });
    }

    deleteMediaItemCheck($event) {
        $event.preventDefault();
        $event.stopPropagation();
        
        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this media item?<br>This cannot be undone.',
            target: $event.target,
            header: 'Delete media item',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete item',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteMediaItem();
            }
        });
    }

    private deleteMediaItem() {
        this.collectionItemService.deleteMediaItem(this.item().id);
    }
}