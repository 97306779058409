import { Component, inject, signal } from '@angular/core';
import { AuthService } from '../auth.service';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { catchError, of, take } from 'rxjs';
import { GraphqlError } from 'src/app/core';

@Component({
    selector: 'app-regenerate-recovery-codes',
    templateUrl: 'regenerate-recovery-codes.component.html',
    standalone: true,
    imports: [
        SvgIconComponent,
        ButtonModule
    ]
})

export class RegenerateRecoveryCodesComponent {

    authService = inject(AuthService);
    dialogRef = inject(DynamicDialogRef);

    state = signal<'warning' | 'recoveryCodes'>('warning');
    recoveryCodes = signal<string[]>([]);
    recoveryCodesCopied = signal(false);
    error = signal<string | null>(null);

    generateCodes() {
        this.error.set(null);

        this.authService.regenerate2FARecoveryCodes().pipe(
            take(1),
            catchError((err: GraphqlError) => {
                console.error(err);
                this.error.set(err.message);
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.recoveryCodes.set(res);
                this.state.set('recoveryCodes');
            }
        });
    }

    copyRecoveryCodes() {
        navigator.clipboard.writeText(this.recoveryCodes().join(', '))
            .then(() => this.recoveryCodesCopied.set(true))
            .catch(err => console.error('Error copying recovery codes: ', err));
    }
}