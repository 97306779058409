<li class="flex items-start gap-3 py-3">
    @if (userImage()) {
        <p-avatar [image]="userImage()" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
    }
    @else {
        <p-avatar [label]="userName().charAt(0).toUpperCase()" shape="circle" styleClass="h-10 w-10 text-2xl"></p-avatar>
    }
    <div>
        <p class="text-sm text-text-primary mb-2">
            <span class="font-semibold">{{ userName() }} </span> 
            {{ operationString() }} 
            @if (operation() === 'create' || operation() === 'update') {
                <span class="font-semibold">{{ dateString() }}</span>
            }
        </p>
        <!-- Hide review changes for now -->
        <!-- @if (log.operation === 'update' && log.diff.length > 0) {
            <p-button (click)="reviewChanges.emit()" label="Review changes" [outlined]="true" size="small"></p-button>
        } -->
        @if (operation() === 'verification' && !!state()) {
            <div class="h-6 w-max px-2 flex items-center rounded-md border text-[10px] font-semibold uppercase" [style.color]="badgeColor()" [style.background-color]="badgeBackgroundColor()" [style.border-color]="badgeColor">
                <p>{{ stateString() }}</p>
            </div>
        }
        <p class="text-[11px] font-normal text-text-secondary mt-2">{{ createdAt() | dateToNow }}</p>
    </div>
</li>