import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { InputTextModule } from "primeng/inputtext";
import { AutoFocusModule } from 'primeng/autofocus';
import { PanelModule } from "primeng/panel";
@Component({
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InputTextModule,
        AutoFocusModule,
        ButtonModule,
        PanelModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    <form id="folderForm" [formGroup]="form" (ngSubmit)="createFolder()">
        <div class="flex gap-2 flex-col p-5">
            <label>Folder Name</label>
            <input pInputText pAutoFocus type="text" formControlName="name" placeholder="Give your folder a name"
                [autofocus]="true" />
        </div>
        <div class="p-dialog-footer">
            <p-button size="large" type="submit" form="folderForm" [disabled]="!form.valid">{{cta}}</p-button>
        </div>
    </form>`
})
export class HomeNewFolderComponent {

    private ref = inject(DynamicDialogRef);
    private config = inject(DynamicDialogConfig<{ name: string; cta: string; }>);

    form = new FormGroup({
        name: new FormControl(this.config.data.name ?? '', [Validators.required, Validators.minLength(3)])
    });
    cta = this.config.data.cta;

    createFolder() {
        this.ref.close(this.form.value);
    }
}
