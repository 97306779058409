<!-- <p-dialog header="Transfer Organisation Ownership"> -->
<form class="w-96" [formGroup]="transferOrganisationForm">
    <div class="flex flex-col gap-1 pb-5">
        <label for="owner">Select an Organisation Admin:</label>
        <span class="p-fluid">
            <p-dropdown appendTo="body" [options]="users" formControlName="owner" optionLabel="displayName"
                placeholder="Select a user" />
        </span>
    </div>
    <div class="flex items-center justify-end">
        <p-button [text]="true" size="large" (click)="cancel()">Cancel</p-button>
        <p-button type="submit" [disabled]="!transferOrganisationForm.valid" size="large"
            (click)="onSubmit()">Transfer</p-button>
    </div>
</form>