
import AdminMailingController from './mailing/admin-mailing.controller';
import AdminMailingService from './mailing/admin-mailing.service';

import AdminBoundariesController from './boundaries/admin-boundaries.controller';
import AppLayoutController from '../layouts/app.layout.controller';
import angular from 'angular';

export default angular
    .module('app.admin', ['ngSanitize', 'ui.router'])
    .service('AdminMailingService', AdminMailingService)
    .controller('AdminMailingController', AdminMailingController)
    .controller('AdminBoundariesController', AdminBoundariesController)
    .controller('AppLayoutController', AppLayoutController)
    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('admin', {
                abstract: true,
                template: require('!raw-loader!../layouts/app.layout.html').default,
                url: '/admin',
                controller: 'AppLayoutController as ctrl',
            })
            .state('admin.design', {
                url: '/design',
                template: '<app-admin-design></app-admin-design>'
            })
            .state('admin.organisations', {
                url: '/organisations?offset&limit&order&q',
                template: `<app-admin-organisations
                    (organisation)="ctrl.organisation($event)"
                    (change)="ctrl.change($event)"
                    [offset]="ctrl.$stateParams.offset"
                    [limit]="ctrl.$stateParams.limit",
                    [order]="ctrl.$stateParams.order"
                    [where]="ctrl.$stateParams.q"></app-admin-organisations>`,
                controllerAs: 'ctrl',
                controller: class AdminOrganisationsController {
                    static $inject = ['$state', '$stateParams'];
                    constructor(public $state, public $stateParams) { }

                    organisation(organisationId: number) {
                        this.$state.go('admin.organisation', { id: Number(organisationId) });
                    }

                    change(state) {
                        this.$state.go('.', state);
                    }
                },
                data: {
                    header: 'Manage Organisations',
                },
                params: {
                    q: {
                        dynamic: true,
                        type: 'json'
                    },
                    offset: {
                        dynamic: true,
                        value: "0"
                    },
                    limit: {
                        dynamic: true,
                        value: "10"
                    },
                    order: {
                        dynamic: true,
                        value: 'reverse:id'
                    }
                }

            })
            .state('admin.organisation', {
                url: '/organisations/:id',
                template: `<app-admin-organisation
                  [organisation-id]="ctrl.organisationId"
                  (dashboard)="ctrl.dashboard($event)"
                  (members)="ctrl.members($event)"
                  (settings)="ctrl.settings($event)">
                </app-admin-organisation>`,
                controllerAs: 'ctrl',
                controller: class {

                    organisationId: number;
                    static $inject = ['$state', '$stateParams', '$ngRedux', 'AuthActions', 'OrganisationsActions'];
                    constructor(private $state, public $stateParams, public $ngRedux, public AuthActions, public OrganisationsActions) {
                        this.organisationId = $stateParams.id;
                    }

                    private loadOrg(id: any) {
                        this.$ngRedux.dispatch(this.AuthActions.setOrganisation(Number(id)));

                        // First we clear the organisations list, then we transfer state, which will trigger reloading the list
                        this.$ngRedux.dispatch(this.OrganisationsActions.clearOrganisations());
                    }
                    dashboard(id: any) {
                        this.loadOrg(Number(id));
                        this.$state.go('home');
                    }

                    members(id: any) {
                        this.loadOrg(Number(id));
                        this.$state.go('organisation-admin.members');
                    }

                    settings(id: any) {
                        this.loadOrg(Number(id));
                        this.$state.go('organisation-admin.settings');
                    }

                }
            })
            .state('admin.projects', {
                url: '/projects?offset&limit&order&q',
                template: `<app-admin-projects
                    (project)="ctrl.project($event)"
                    (change)="ctrl.change($event)"
                    [offset]="ctrl.$stateParams.offset"
                    [limit]="ctrl.$stateParams.limit",
                    [order]="ctrl.$stateParams.order"
                    [where]="ctrl.$stateParams.q"></app-admin-projects>`,
                controllerAs: 'ctrl',
                controller: class AdminProjectsController {
                    static $inject = ['$state', '$stateParams'];
                    constructor(public $state, public $stateParams) { }

                    project(id: number) {
                        this.$state.go('admin.project', { id });
                    }


                    change(state) {
                        this.$state.go('.', state);
                    }
                },
                data: {
                    header: 'Manage Projects',
                },
                params: {
                    q: {
                        dynamic: true,
                        type: 'json'
                    },
                    offset: {
                        dynamic: true,
                        value: "0"
                    },
                    limit: {
                        dynamic: true,
                        value: "10"
                    },
                    order: {
                        dynamic: true,
                        value: 'reverse:id'
                    }
                }

            })
            .state('admin.project', {
                url: '/project/:id',
                template: `<app-admin-project
                  [project-id]="ctrl.projectId"
                  (dashboard)="ctrl.dashboard($event)"
                  (organisation)="ctrl.organisation($event)"
                  >
                </app-admin-project>`,
                controllerAs: 'ctrl',
                controller: class {

                    projectId: number;
                    static $inject = ['$state', '$stateParams', '$ngRedux', 'AuthActions', 'OrganisationsActions'];
                    constructor(private $state, $stateParams) {
                        this.projectId = $stateParams.id;
                    }

                    dashboard(event: { orgSlug: string; projectSlug: string; }) {
                        const { orgSlug, projectSlug } = event;
                        this.$state.go('project.dashboard', { orgSlug, id: projectSlug });
                    }

                    organisation(id: number) {
                        this.$state.go('admin.organisation', { id });
                    }
                }
            })
            .state('admin.users', {
                url: '/users?offset&limit&order&s&q',
                template: `<app-admin-users
                    (user)="ctrl.user($event)"
                    (change)="ctrl.change($event)"
                    [offset]="ctrl.$stateParams.offset"
                    [limit]="ctrl.$stateParams.limit",
                    [order]="ctrl.$stateParams.order"
                    [search]="ctrl.$stateParams.s"
                    [where]="ctrl.$stateParams.q"></app-admin-users>`,
                controllerAs: 'ctrl',
                controller: class AdminUsersController {
                    static $inject = ['$state', '$stateParams'];
                    constructor(public $state, public $stateParams) { }

                    user(user) {
                        this.$state.go('admin.user', { id: user.id });
                    }

                    change(state) {
                        this.$state.go('.', state);
                    }
                },
                data: {
                    header: 'Users',
                    description: 'Manage users'
                },
                params: {
                    s: {
                        dynamic: true
                    },
                    q: {
                        dynamic: true,
                        type: 'json'
                    },
                    offset: {
                        dynamic: true,
                        value: "0"
                    },
                    limit: {
                        dynamic: true,
                        value: "10"
                    },
                    order: {
                        dynamic: true,
                        value: 'reverse:id'
                    }
                }

            })
            .state('admin.user', {
                url: '/users/:id',
                template: '<app-admin-user [user-id]="ctrl.userId" (impersonate)="ctrl.impersonateUser()" (user-delete)="ctrl.userDelete()"></app-admin-user>',
                controllerAs: 'ctrl',
                controller: class {

                    userId: number;
                    static $inject = ['$scope', '$ngRedux', 'AuthActions', '$state', '$stateParams'];
                    constructor($scope, $ngRedux, AuthActions, private $state, private $stateParams) {
                        $scope.$on('$destroy', $ngRedux.connect(null, AuthActions)(this));
                        this.userId = $stateParams.id;
                    }

                    impersonateUser() {
                        (this as any).impersonate(this.$stateParams.id).then(() => {
                            this.$state.go('home');
                        });
                    };

                    userDelete() {
                        this.$state.go('admin.users');
                    }

                }
            })
            .state('admin.mailing', {
                url: '/mailing',
                template: require('!raw-loader!./mailing/admin-mailing.html').default,
                controller: 'AdminMailingController as ctrl',
                data: {
                    header: 'Mailing',
                    description: 'Send mail to users of a project'
                },
                resolve: {
                    projects: ['AdminMailingService', function (AdminMailingService) {
                        return AdminMailingService.getProjects();
                    }],
                    templates: ['AdminMailingService', function (AdminMailingService) {
                        return AdminMailingService.getTemplates();
                    }],
                    domains: ['AdminMailingService', function (AdminMailingService) {
                        return AdminMailingService.getDomains();
                    }]
                }
            })
            .state('admin.requests', {
                url: '/requests?offset&limit&order&q',
                template: `<app-admin-requests
                    (request)="ctrl.request($event)"
                    (change)="ctrl.change($event)"
                    [offset]="ctrl.$stateParams.offset"
                    [limit]="ctrl.$stateParams.limit",
                    [order]="ctrl.$stateParams.order"
                    [search]="ctrl.$stateParams.s"
                    [where]="ctrl.$stateParams.q"></app-admin-requests>`,
                controllerAs: 'ctrl',
                controller: class AdminRequestsController {
                    static $inject = ['$state', '$stateParams'];
                    constructor(public $state, public $stateParams) { }

                    request(request) {
                        this.$state.go('admin.request', { id: request.id });
                    }

                    change(state) {
                        this.$state.go('.', state);
                    }
                },
                params: {
                    q: {
                        dynamic: true,
                        type: 'json'
                    },
                    offset: {
                        dynamic: true,
                        value: "0"
                    },
                    limit: {
                        dynamic: true,
                        value: "10"
                    },
                    order: {
                        dynamic: true,
                        value: 'reverse:requestedAt'
                    }
                }
            })
            .state('admin.request', {
                url: '/requests/:id',
                // template: require('!raw-loader!./requests/admin-request.html').default,
                template: '<app-admin-request [id]="ctrl.id"></app-admin-request>',
                controllerAs: 'ctrl',
                controller: class AdminRequestController {
                    static $inject = ['$stateParams'];
                    constructor(public $stateParams) { }
                    id = this.$stateParams.id;
                }
            })
            .state('admin.boundaries', {
                url: '/boundaries',
                template: require('!raw-loader!./boundaries/admin-boundaries.html').default,
                controller: 'AdminBoundariesController as ctrl',
                data: {
                    header: 'Boundaries',
                },
                resolve: {
                    boundarySets: ['Boundaries', (Boundaries) => Boundaries.getBoundarySets()]
                }
            });
    }]);