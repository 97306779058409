<div class="flex flex-col gap-3">
    <label>{{ label() }}</label>
    <div class="flex items-center gap-[10px]" (click)="focusInput()">
        @for (input of "123456"; track input; let index = $index) {
            <div class="h-16 w-11 flex items-center justify-center bg-grey-10 rounded-[4px] border border-grey-border text-text-primary text-4xl font-semibold leading-none" [ngClass]="{'ring-2 ring-primary': isActive(index)}">
                @if (twoFACode()[index]) {
                    {{ twoFACode()[index] }}
                }
                @else {
                    <span class="text-grey-30">1</span>
                }
            </div>
        }
    </div>

    <input
        #twoFAInput
        type="text"
        name="twoFAInput"
        [(ngModel)]="twoFAValue"
        (ngModelChange)="codeChanged()"
        [maxlength]="6"
        [min]="6"
        [max]="6"
        class="sr-only opacity-0" />

    <div class="h-8">
        @if (verifying()) {
            <div class="flex items-center gap-2">
                <svg-icon src="/assets/icons/spinner.svg" class="h-5 w-5"></svg-icon>
                <p class="font-semibold">Verifying...</p>
            </div>
        }
    
        @if (error()) {
            <p class="text-danger">There was an error with your code, please try again.</p>
        }
    </div>
</div>