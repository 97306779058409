import { Component, inject } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from "primeng/dropdown";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ApiService } from "src/app/core";
import { UserService } from 'src/app/core/services/user.service';
import { UsersResponse } from '../../../core/models/user.model';

@Component({
    templateUrl: './transfer-organisation.component.html',
    selector: 'app-transfer-organisation',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        ButtonModule,
        DropdownModule
    ],
    providers: []
})
export class TransferOrganisationComponent {

    api = inject(ApiService);
    dialogRef = inject(DynamicDialogRef);
    dialogConfig: DynamicDialogConfig<{ organisationId: number }> = inject(DynamicDialogConfig<{ organisationId: number }>);

    private organisationId: number = this.dialogConfig.data.organisationId;

    transferOrganisationForm = new FormGroup({
        owner: new FormControl<UsersResponse>(null, [Validators.required])
    });

    users: UsersResponse[] | [] = [];

    private readonly transferOrganisationQuery = `mutation TransferOrganisationOwnership($organisationId: Int!, $userId: Int!) {
        transferOrganisationOwnership(input: {
            userId: $userId,
            organisationId: $organisationId
        }) { role }
    }`;

    private readonly getOrgAdminsQuery = `query AAGetOrgTransferUsers($organisationId: Int!) {
        organisation(id: $organisationId) {
            memberships( where: { role: "admin"}){
                user { id, displayName, username }
            }
        }
    }`;

    constructor(
        private apiService: ApiService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.api.graphql<{ organisation: { memberships: { user: UsersResponse }[] } }>(this.getOrgAdminsQuery, { organisationId: this.organisationId }).subscribe((response) => {
            this.users = response.organisation.memberships.filter(m => !!m.user).map(m => m.user);
        });
    }

    getUsers(event: { originalEvent: Event; query: string; }) {
        this.userService.searchUsers(event.query).subscribe(res => this.users = res.users);
    }

    onSubmit() {
        const { owner } = this.transferOrganisationForm.value;
        this.apiService.graphql(this.transferOrganisationQuery, { userId: owner.id, organisationId: this.organisationId })
            .subscribe({
                complete: () => this.dialogRef.close(true),
                error: (error) => this.dialogRef.close(error)
            });
    }

    cancel() {
        this.dialogRef.close();
    }
}
