import { Component, Input, Output, EventEmitter, inject } from "@angular/core";
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgFor } from "@angular/common";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogRef } from "primeng/dynamicdialog";

const _ = require('lodash');

interface TemplateType {
    type: string;
    title: string;
    icon: string;
    description: string;
}

@Component({
    templateUrl: './project-create-page.component.html',
    selector: 'app-project-create-page',
    standalone: true,
    imports: [
        NgFor,
        ToastModule,
        ButtonModule,
        DialogModule,
        InputTextModule,
        ReactiveFormsModule
    ],
    providers: [MessageService]
})
export class ProjectCreatePageComponent {

    private ref = inject(DynamicDialogRef);

    @Input() visible: boolean = false;

    templateTypes: TemplateType[] = [
        {
            type: 'wysiwyg',
            title: 'custom',
            icon: 'fa-pencil-alt',
            description: 'Create your own page, using the Coreo page builder.',
        },
        {
            type: 'collection',
            title: 'collection',
            icon: 'fa-list',
            description: 'A List/Detail view of a collection',
        }
    ];

    createPageForm = new FormGroup({
        name: new FormControl<string>('', [Validators.required]),
        template: new FormControl<TemplateType>(this.templateTypes[0], [Validators.required]),
    });

    setTemplate(type: TemplateType) {
        this.createPageForm.controls['template'].setValue(type);
    }

    onSubmit(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        if (!this.createPageForm.valid) {
            return;
        }

        this.ref.close(this.createPageForm.value);
    }

    close(data?: typeof this.createPageForm.value) {
        this.ref.close(data);
    }
}
