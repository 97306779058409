import { Component, input, OnInit } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { Question } from '../form.types';
import { CalendarModule } from 'primeng/calendar';
import { formatISO } from 'date-fns';

@Component({
    selector: 'app-date-picker',
    templateUrl: 'date-picker.component.html',
    standalone: true,
    imports: [
        FormsModule,
        CalendarModule
    ]
})

export class DatePickerComponent implements OnInit {

    question =input.required<Question>();
    form =input.required<FormGroup>();

    private _date: Date;

    public get date() {
        return this._date;
    }

    public set date(val: Date) {
        this._date = val;
        if (val) {
            let dateString: string;
            if (this.question().type === 'datetime') {
                dateString = formatISO(val);
            } else {
                dateString = formatISO(val, { representation: 'date' });
            }
            
            this.form().controls[this.question().path].setValue(dateString);
        } else {
            this.form().controls[this.question().path].setValue(null);
        }
        this.form().markAsDirty();
    }

    constructor() { }

    ngOnInit() {
        this._date = this.form().controls[this.question().path].value ? new Date(this.form().controls[this.question().path].value) : null;
    }
}