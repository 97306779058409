import { Environment } from "./environment.common";

export const environment: Environment = {
    apiUrl: 'https://api.coreo.io',
    adminHostname: 'admin.coreo.io',
    apiHostname: 'api.coreo.io',
    apiProtocol: 'https',
    adminPort: 443,
    apiPort: null,
    adminProtocol: 'https',
    mapsUrl: 'https://maps.coreo.io',
    docsUrl: 'https://docs.coreo.io',
    googleAnalyticsId: 'UA-87087454-5',
    mapboxApiKey: 'pk.eyJ1IjoibmF0YXBwIiwiYSI6ImNqZHg1MXZndDNxM2UzM3FwdnRsdW96cWIifQ.szIwfw6q7QTADsLCQXCKiw',
    androidUrl: 'https://play.google.com/store/apps/details?id=com.natural_apptitude.coreo_app',
    iosUrl: 'https://apps.apple.com/us/app/coreo/id1512776044',
    production: false,
    imagesProxyEnabled: false,
    imagesCdnUrl: 'https://coreo.twic.pics',
    cdnUrl: 'https://cdn.coreo.io',
    bingMapsKey: 'AlWr5u4AGzUgzhE-m2d3Zbpx0uVOu1M4c2Lfv1ACN7LM998xxx_35IKMlef7PC0A'
} as const;