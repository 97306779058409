import { ChangeDetectionStrategy, Component, inject, OnInit, signal, viewChild } from "@angular/core";
import { AuthService } from "../auth.service";
import { ButtonModule } from "primeng/button";
import { FormsModule } from "@angular/forms";
import { catchError, of } from "rxjs";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { CommonModule } from "@angular/common";
import { TwoFAInputComponent } from "../2fa-input/2fa-input.component";
import { SvgIconComponent } from "angular-svg-icon";

@Component({
    templateUrl: './enable-2fa.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        TwoFAInputComponent,
        SvgIconComponent
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class Enable2FAComponent implements OnInit {

    auth = inject(AuthService);
    dialogRef = inject(DynamicDialogRef);

    twoFAInputComponent = viewChild<TwoFAInputComponent>('twoFAInput');

    state = signal<'setup' | 'recoveryCodes' | 'enabled'>('setup');
    qrCode = signal<string>('');
    secret = signal<string>('');
    loaded = signal(false);
    verifying = signal(false);
    recoveryCodes = signal<string[]>([]);
    recoveryCodesCopied = signal(false);

    ngOnInit() {
        this.auth.setup2FA().subscribe(res => {
            this.qrCode.set(res.qrCode);
            this.secret.set(res.secret);
            this.loaded.set(true);
        });
    }

    submit(code: string) {
        this.verifying.set(true);
        this.auth.confirm2FA(code)
            .pipe(catchError(err => {
                console.error('ERROR', err, err.errors);
                this.twoFAInputComponent().error.set(true);
                return of(null);
            })).subscribe(res => {
                if (!!res) {
                    this.recoveryCodes.set(res.recoveryCodes);
                    this.state.set('enabled');
                }
                this.verifying.set(false);
            });
    }

    copySecret() {
        navigator.clipboard.writeText(this.secret())
            .catch(err => console.error('Error copying secret: ', err));
    }

    copyRecoveryCodes() {
        navigator.clipboard.writeText(this.recoveryCodes().join(', '))
            .then(() => this.recoveryCodesCopied.set(true))
            .catch(err => console.error('Error copying recovery codes: ', err));
    }
}