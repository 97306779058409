import { z } from 'zod';

export const OrganisationTierSchema = z.object({
    id: z.number().optional(),
    name: z.string()
});

export const OrganisationOwnerSchema = z.object({
    id: z.number(),
    displayName: z.string()
});

export const OrganisationTableItemSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    createdAt: z.string(),
    freeTrialEnd: z.string().nullable(),
    freeTrialExpired: z.boolean(),
    tier: OrganisationTierSchema
});

export const OrganisationSchema = z.object({
    name: z.string(),
    slug: z.string(),
    seats: z.number(),
    contributorSeats: z.number(),
    organisationTierId: z.number(),
    freeTrialEnd: z.string().nullable(),
    memberships: z.array(
        z.object({
          id: z.number()
        })
    ),
    owner: OrganisationOwnerSchema
});

export const OrganisationInfoSchema = z.object({
    organisation: OrganisationSchema,
    organisationTiers: OrganisationTierSchema.array()
});

export const OrganisationTableItemsSchema = z.object({
    organisations: OrganisationTableItemSchema.array(),
    count: z.number()
});

export type Organisation = z.infer<typeof OrganisationSchema>;
export type OrganisationTier = z.infer<typeof OrganisationTierSchema>;
export type OrganisationTableItem = z.infer<typeof OrganisationTableItemSchema>;
