import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableModule, TableRowReorderEvent } from 'primeng/table';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { AddEditAttributeModalComponent } from '../add-edit-attribute-modal/add-edit-attribute-modal.component';
import { TooltipModule } from 'primeng/tooltip';

export type CollectionAttribute = {
    id: number;
    label: string;
    type: string;
    visible: boolean;
    order: number;
}

type CollectionAttributesResponse = {
    project: {
        collections: {
            attributes: CollectionAttribute[]
        }[];
    };
}

@Component({
    selector: 'app-attribute-table',
    templateUrl: 'attribute-table.component.html',
    imports: [
        CommonModule,
        TableModule,
        ButtonModule,
        DynamicDialogModule,
        TooltipModule
    ],
    providers: [
        DialogService
    ],
    standalone: true
})

export class CollectionAttributeTableComponent implements OnInit {

    @Input() projectId: number;
    @Input() collectionId: number;

    @Output() hasUpdated: EventEmitter<void> = new EventEmitter();

    public loading: boolean = false;
    public attributes: CollectionAttribute[] = [];

    private dialogRef: DynamicDialogRef | undefined;

    constructor(
        private apiService: ApiService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        this.loadAttributes();
    }

    private loadAttributes() {
        this.loading = true;

        const query = `query loadCollectionAttributes($projectId: Int!, $collectionId: Int!){
            project(id: $projectId){
                collections(where: { id: $collectionId }){
                    attributes {
                        id
                        label
                        type
                        visible
                        order
                    }
                }
            }
        }`;

        const input = { 
            projectId: this.projectId, 
            collectionId: this.collectionId, 
        };

        this.apiService.graphql<CollectionAttributesResponse>(query, input).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.attributes = res.project.collections[0].attributes;
            }
            this.loading = false;
        });
    }

    public newAttribute() {
        const header = 'Add attribute';
        const data = { 
            projectId: this.projectId,
            collectionId: this.collectionId 
        }
        this.openAttributeModal(header, data);
    }

    public editAttribute(attribute: CollectionAttribute) {
        const header = 'Edit attribute';
        const data = { 
            projectId: this.projectId,
            collectionId: this.collectionId,
            attribute
        }
        this.openAttributeModal(header, data);
    }

    private openAttributeModal(header: string, data: any) {
        this.dialogRef = this.dialogService.open(AddEditAttributeModalComponent, { 
            header,
            data,
            width: '420px'
        });

        this.dialogRef.onClose.subscribe((success: boolean) => {
            if (success) {
                this.loadAttributes();
                this.hasUpdated.emit();
            }
        });
    }

    public toggleVisibility(attribute: CollectionAttribute) {
        this.loading = true;

        const query = `mutation updateAttribute($input: collectionAttributeUpdateInput!){
            updateCollectionAttribute(input: $input){
                id
                label
                type
                visible
                order
            }
        }`;

        const input = {
            id: attribute.id,
            visible: !attribute.visible
        }

        this.apiService.graphql<{ updateCollectionAttribute: CollectionAttribute }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.attributes = this.attributes.map(a => {
                    if (a.id === res.updateCollectionAttribute.id) {
                        return res.updateCollectionAttribute;
                    } else {
                        return a;
                    }
                });
            }
            this.loading = false;
        });
    }

    public reorderRows($event: TableRowReorderEvent) {
        const { dragIndex, dropIndex } = $event;

        if (dragIndex === dropIndex) {
            return;
        }

        this.loading = true;
        
        const newAttributes = this.attributes.map((a, index) => {
            return {
                ...a,
                order: index
            }
        });

        const query = `mutation updateAttributes($input: AttributesUpdateInput!){
            updateAttributes(input: $input)
        }`;

        const input = {
            attributes: newAttributes
        }

        this.apiService.graphql<{ updateCollectionAttribute: CollectionAttribute }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.attributes = newAttributes;
            } else {
                // if there was an error then the order returns to how it was
                this.attributes = [...this.attributes.sort((a, b) => a.order - b.order)];
            }
            this.loading = false;
        });
    }

    public refresh() {
        this.loadAttributes();
    }
}