import { Component, input, OnInit } from '@angular/core';
import { Question } from '../form.types';
import { FormGroup } from '@angular/forms';
import { YesNoPipe } from 'src/app/shared/pipes/yesno.pipe';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-yes-no',
    templateUrl: 'yes-no.component.html',
    standalone: true,
    imports: [
        CommonModule,
        YesNoPipe
    ]
})

export class YesNoComponent implements OnInit {

    question = input.required<Question>();
    form = input.required<FormGroup>();

    public choices: boolean[] = [true, false];

    constructor() { }

    ngOnInit() { }

    get value() {
        return this.form().controls[this.question().path].value;
    }

    public handleClick(choice: boolean) {
        if (choice === this.value) {
            if (this.question().required) {
                return;
            }
            this.form().controls[this.question().path].setValue(null);
        } else {
            this.form().controls[this.question().path].setValue(choice);
        }
        this.form().markAsDirty();
    }   
}