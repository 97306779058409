import { Injectable } from "@angular/core";
import { Geometry } from "geojson";
import { Observable, combineLatest, from, map, mergeAll, mergeMap, of, switchMap, take, tap, toArray } from "rxjs";
import { ApiService } from "src/app/core";
import mime from 'mime';

export interface RecordUpdateData {
    id: number;
    data: { [path: string]: string };
    deleteAssociates: { id: number; attributeId: number }[];
    newAssociates: { id: number; attributeId: number }[];
    childRecords: NewChildRecord[];
    deleteAttachments: { id: number; }[];
    newAttachments: RecordAttachment[];
}

export interface RecordAttachment {
    id: number;
    projectId: number;
    attributeId: number;
    file?: File | Blob;
    url: string;
    mimeType: string;
};

export interface NewRecord {
    projectId: number;
    surveyId: number;
    geometry: Geometry;
    data: { [path: string]: string };
    associates: { id: number; attributeId: number }[];
    childRecords: NewChildRecord[];
    attachments: RecordAttachment[];
}

export interface NewChildRecord extends NewRecord {
    attributeId: number;
}

interface CreateAttachmentInput {
    url: string;
    attributeId: number;
}

interface DeleteAttachmentInput {
    id: number;
}

// interface RecordCreateInput {
//     data: any;
//     geometry: Geometry;
//     associates: CreateReferenceInput[];
//     attachments: CreateAttachmentInput[];
//     surveyId: number;
//     projectId: number;
// }

interface RecordCreateReferenceInput {
    data: any;
    geometry: Geometry;
    associates: CreateReferenceInput[];
    attachments: CreateAttachmentInput[];
    surveyId: number;
}

interface CreateReferenceInput {
    record?: RecordCreateReferenceInput; 
    id?: number;
    attributeId: number;
}

interface DeleteReferenceInput {
    id: number; 
    attributeId: number;
}

interface ChangeRecordInput {
    id: number;
    geometry?: Geometry;
    data: { [path: string]: string };
    createReference: CreateReferenceInput[]
    deleteReference: DeleteReferenceInput[];
    createAttachment: CreateAttachmentInput[];
    deleteAttachment: DeleteAttachmentInput[];
}

@Injectable({
    providedIn: 'root'
})
export class RecordsService {

    constructor(
        private apiService: ApiService,
    ) {}

    private getExtension(mimeType: string) {
        // mime.extension returns false for 'image/jpg' so we have to do this extra check
        // also make sure we return the 3 character extension to support windows users
        if (mimeType === 'image/jpg' || mimeType === 'image/jpeg') {
          return 'jpg';
        }
        return mime.getExtension(mimeType);
    }

    private getPresignedUrl(attachment: RecordAttachment): Observable<{ url: string; attachment: RecordAttachment }> {
        const extension = this.getExtension(attachment.mimeType);
        const filename = new Date().valueOf() + (extension ? `.${extension}` : '');
        const { mimeType, projectId } = attachment;

        const query = `query getPresignedUrl($projectId: Int!, $fileName: String!, $contentType: String!){
                presignedURL(projectId: $projectId, fileName: $fileName, contentType: $contentType)
            }`;

        return this.apiService.graphql<{ presignedURL: string }>(query, {
            projectId,
            fileName: filename,
            contentType: mimeType
        }).pipe(
            take(1),
            tap(res => console.log(res)),
            map(res => ({ url: res.presignedURL, attachment })),
        );
    }

    /** There seems to be an issues with angular http client auth headers interfering with amazon's presigned url auth so using fetch instead and converting to an observable */
    private async uploadAttachment(url: string, attachment: RecordAttachment): Promise<CreateAttachmentInput> {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
              'Content-Type': attachment.mimeType
            },
            body: attachment.file,
          });
        
          if (!response.ok) {
            throw new Error('Attachment upload failed');
          }
        
          return { url: url.split('?')[0], attributeId: attachment.attributeId}
    }

    private uploadAttachments(attachments: RecordAttachment[]): Observable<CreateAttachmentInput[]> {        
        const attachmentsObservable: Observable<RecordAttachment[]> = of(attachments);
        
        return attachmentsObservable.pipe(
            mergeAll(),
            mergeMap(attachment => this.getPresignedUrl(attachment)),
            tap(res => console.log('presignedUrl', res)),
            mergeMap(res => from(this.uploadAttachment(res.url, res.attachment))),
            tap(res => console.log('uploadAttachment', res)),
            toArray()
        );
    }

    /** Use for new records when we add that to the admin area */
    // public createRecord(record: NewRecordData, attachments: CreateAttachmentInput[], associates: []): Observable<number> {

    //     const query = `mutation createRecord(
    //         $surveyId: Int!,
    //         $projectId: Int,
    //         $data: SequelizeJSON,
    //         $geometry: GeoJSONGeometryInput,
    //         $associates: [associateInput],
    //         $attachments: [attachmentInput]
    //     ){
    //         createRecord(input: {
    //             surveyId: $surveyId,
    //             projectId: $projectId,
    //             data: $data,
    //             geometry: $geometry,
    //             associates: $associates,
    //             attachments: $attachments
    //         }){
    //             id
    //         }
    //     }`;

    //     const input = {
    //         surveyId: record.surveyId,
    //         ProjectId: record.projectId,
    //         data: record.data,
    //         geometry: record.geometry,
    //         associates: record.associates,
    //         attachments: attachments
    //     }

    //     return this.apiService.graphql<{ createRecord: { id: number } }>(query, input).pipe(
    //         take(1),
    //         map(res => res.createRecord.id)
    //     );
    // }

    private createAssociateInput(record: NewChildRecord): Observable<CreateReferenceInput> {
        const hasAttachments: boolean = record.attachments.length > 0;
        const hasChildren: boolean = record.childRecords.length > 0;

        const recordInput: RecordCreateReferenceInput = {
            data: record.data,
            geometry: record.geometry,
            surveyId: record.surveyId,
            attachments: [],
            associates: record.associates
        }

        if (hasAttachments && !hasChildren) {
            /** Attachments to submit */
            return this.uploadAttachments(record.attachments).pipe(
                switchMap(attachments => {
                    const r = Object.assign({}, recordInput, { attachments });
                    const input: CreateReferenceInput = {
                        attributeId: record.attributeId,
                        record: r
                    }
                    return of(input);
                })
            );
        } else if (!hasAttachments && hasChildren) {
            /** Children to submit */
            return this.createAssociateInputs(record.childRecords).pipe(
                switchMap(childAssociates => {
                    const associates = [...record.associates, ...childAssociates];
                    const r = Object.assign({}, recordInput, { associates });
                    const input: CreateReferenceInput = {
                        attributeId: record.attributeId,
                        record: r
                    }
                    return of(input);
                })
            );
        } else if (hasAttachments && hasChildren) {
            /** Attachments and children to submit */
            const obs = combineLatest([
                this.uploadAttachments(record.attachments),
                this.createAssociateInputs(record.childRecords)
            ]);

            return obs.pipe(
                switchMap(([attachments, childAssociates]) => {
                    const associates = [...record.associates, ...childAssociates];
                    const r = Object.assign({}, recordInput, { attachments, associates });
                    const input: CreateReferenceInput = {
                        attributeId: record.attributeId,
                        record: r
                    }
                    return of(input);
                })
            );
        } else {
            /** No attachment or new child records to submit */
            const input: CreateReferenceInput = {
                attributeId: record.attributeId,
                record: recordInput
            }
            return of(input);
        }
    }

    private createAssociateInputs(childRecords: NewChildRecord[]): Observable<CreateReferenceInput[]> {
        const recordsObservable: Observable<NewChildRecord[]> = of(childRecords);

        return recordsObservable.pipe(
            mergeAll(),
            mergeMap(record => this.createAssociateInput(record)),
            tap(res => console.log('createAssociateInputs', res)),
            toArray()
        );
    }

    private changeRecord(input: ChangeRecordInput): Observable<{id: number; updatedAt: string}> {
        const query = `mutation changeRecord($input: ChangeRecordInput!){
            changeRecord(input: $input){
                id
                updatedAt
            }
        }`;


        return this.apiService.graphql<{ changeRecord: { id: number; updatedAt: string } }>(query, { input }).pipe(
            map(res => res.changeRecord)
        );
    }

    public updateRecord(record: RecordUpdateData, geometry: Geometry): Observable<{id: number; updatedAt: string}> {
        const  { 
            id, 
            data, 
            newAttachments, 
            deleteAttachments, 
            newAssociates, 
            deleteAssociates,
            childRecords
        } = record;

        const hasAttachments: boolean = newAttachments.length > 0;
        const hasChildren: boolean = childRecords.length > 0;

        const changeRecordInput: ChangeRecordInput = {
            id,
            data,
            createReference: newAssociates,
            deleteReference: deleteAssociates,
            createAttachment: [],
            deleteAttachment: deleteAttachments,
        }

        if (geometry !== undefined) {
            changeRecordInput['geometry'] = geometry;
        }

        if (hasAttachments && !hasChildren) {
            /** Has attachments */
            return this.uploadAttachments(newAttachments).pipe(
                switchMap(attachments => {
                    const input: ChangeRecordInput = {
                        ...changeRecordInput,
                        createAttachment: attachments
                    }
                    return this.changeRecord(input);
                })
            );
        } else if (!hasAttachments && hasChildren) {
            /** Has children */
            return this.createAssociateInputs(childRecords).pipe(
                switchMap(childAssociates => {
                    const input: ChangeRecordInput = {
                        ...changeRecordInput,
                        createReference: [...newAssociates, ...childAssociates]
                    }
                    return this.changeRecord(input);
                })
            )
        } else if (hasAttachments && hasChildren) {
            /** Has attachment and children */
            const obs = combineLatest([
                this.uploadAttachments(newAttachments),
                this.createAssociateInputs(childRecords)
            ]);

            return obs.pipe(
                switchMap(([attachments, childAssociates]) => {
                    const input: ChangeRecordInput = {
                        ...changeRecordInput,
                        createAttachment: attachments,
                        createReference: [...newAssociates, ...childAssociates]
                    }
                    return this.changeRecord(input);
                })
            )
        } else {
            /** No attachments or children */
            return this.changeRecord(changeRecordInput);
        }
    }

    public updateRecordState(id: number, state: number): Observable<number> {
        const query = `mutation updateRecordState($input: RecordVerifyInput!){
            verifyRecord(input: $input)
        }`;

        return this.apiService.graphql<{ verifyRecord: boolean }>(query, {
            input: {
                id,
                state
            }
        }).pipe(
            map(res => {
                if (!!res.verifyRecord) {
                    return state;
                }
            })
        );
    }

    public deleteAttachment(recordId: number, attachment: RecordAttachment): Observable<number> {
        const query = `mutation changeRecord(
            $id: Int!, 
            $deleteAttachment: [DeleteAttachmentInput]
        ){
            changeRecord(input: { 
                id: $id, 
                deleteAttachment: $deleteAttachment
            }){
                id
            }
        }`;

        return this.apiService.graphql<{ changeRecord: { id: number } }>(query, {
            id: recordId,
            deleteAttachment: [{ id: attachment.id }],
        }).pipe(
            map(res => res.changeRecord.id)
        );
    }

    public deleteRecord(id: number): Observable<number> {
        const query = `mutation deleteRecord($id: Int!){
            deleteRecord(input: { id: $id })
        }`;

        return this.apiService.graphql<{ deleteRecord: number }>(query, { id }).pipe(
            map(res => res.deleteRecord)
        );
    }

    public cloneRecord(id: number): Observable<number> {
        const query = `mutation cloneRecord($id: Int!){
            cloneRecord(input: { id: $id }){
                id
            }
        }`;

        return this.apiService.graphql<{ cloneRecord: { id: number } }>(query, { id }).pipe(
            map(res => res.cloneRecord.id)
        );
    }
}