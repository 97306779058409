<div class="h-full w-full">
    <div class="h-100 text-text-primary">
        @switch (state()) {
        @case ('setup') {
        <form>
            <p class="font-semibold mb-10">You'll need to download an authenticator app such as Google Authenticator,
                Duo Mobile or Microsoft Authenticator.</p>

            @if(loaded()){
            <div class="flex items-start gap-5 text-sm">
                <div class="w-[292px] flex flex-col items-center gap-5">
                    <p class="font-semibold text-center">Scan the QR code below with your authenticator app.</p>
                    <img [src]="qrCode()" />
                    <p class="text-center">Can't scan? Enter this code into your authenticator app.
                        <span class="block font-semibold">{{ secret() }}</span>
                    </p>

                </div>

                <div class="pt-[70px]">
                    <p class="font-semibold mb-[10px]">Copy the code from your authenticator app and enter it below</p>
                    <app-two-fa-input #twoFAInput [verifying]="verifying()"
                        (codeValid)="submit($event)"></app-two-fa-input>
                </div>
            </div>
            }
        </form>
        }
        @case ('recoveryCodes') {
        <div class="h-full w-full flex items-center justify-center">

            <div class="px-10 flex flex-col items-center">
                <h2 class="text-2xl font-semibold text-center mb-16">Single Use Recovery Codes</h2>
                <div class="flex items-center gap-10">
                    <div class="w-1/2 grid grid-cols-2 gap-5">
                        @for (code of recoveryCodes(); track code) {
                        <p class="text-xs font-mono font-semibold">{{ code }}</p>
                        }
                    </div>
                    <div class="w-1/2">
                        <div class="flex flex-col">
                            <p class="text-xs mb-4">Copy the codes, and keep them safe. If you do not have access to
                                either these codes or your authenticator you will be locked out of your account.</p>
                            <p class="text-xs mb-5">You will not see these again. If you lose these codes, but still
                                have access to your account you can regenerate new ones in you account settings.</p>

                            <div class="flex items-center gap-4">
                                <p-button label="Copy codes" [outlined]="true" (click)="copyRecoveryCodes()"></p-button>
                                @if (recoveryCodesCopied()) {
                                <p class="text-[#57B771] text-xs flex items-center"><svg-icon
                                        src="/assets/icons/tick.svg" class="inline-block mr-1"></svg-icon>Codes copied
                                </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
        @case ('enabled') {
        <div class="h-full w-full flex items-center justify-center">
            <div class="max-w-[561px] flex flex-col items-center">
                <svg-icon class="mb-10" src="/assets/icons/lock-closed.svg"></svg-icon>
                <h2 class="text-2xl font-semibold text-center mb-10">Two-Factor Authentication has been enabled</h2>
                <p class="text-sm text-center mb-5">If you lose your authenticator app you can still log in using your
                    single use backup codes. Please make a copy of these before continuing.</p>
                <p-button label="Generate codes" size="large" [outlined]="true"
                    (click)="state.set('recoveryCodes')"></p-button>
            </div>
        </div>
        }
        }
    </div>
    <div class="h-16 flex items-end justify-end">
        @if (state() !== 'setup') {
        <p-button size="large" label="Finish setup" type="button" (click)="dialogRef.close(true)"></p-button>
        }
        @else {
        <p-button size="large" label="Cancel" type="button" [outlined]="true" (click)="dialogRef.close()"></p-button>
        }
    </div>
</div>