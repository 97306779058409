<p-toast position="bottom-center" [life]="2000"></p-toast>
<div class="flex-grow flex items-center justify-center bg-white relative h-full w-full">
    @if (signupComplete()) {
        <div class="max-w-[488px] flex flex-col items-center gap-6">
            <svg-icon src="/assets/icons/spinner.svg" class="h-24 w-24"></svg-icon>
            <h1 class="font-semibold text-2xl">One more thing to do...</h1>
            <p class="text-sm text-text-primary text-center">We've sent you an email. Please press the link in it to verify it's you, then you'll be automatically signed into Coreo</p>
            <a href="/signin" class="h-11 px-6 border  bg-primary rounded-md flex-none flex items-center justify-center text-white text-sm font-semibold">Go to sign in</a>
        </div>
    }
    @else {
        <div class="max-w-md w-full px-4">
            <h1 class="font-semibold text-2xl mb-[30px]">Create a free trial account</h1>
            <p class="text-xs text-text-primary mb-[30px]">Your free trial will last 30 days</p>
            <form [formGroup]="signupForm" (ngSubmit)="submit()" class="mb-5">
                <div class="flex flex-col gap-1 mb-6">
                    <label>Email</label>
                    <input name="email" type="email" pInputText autocomplete="email" required formControlName="email" placeholder="Email address">
                    @if (signupForm.get('email').touched && signupForm.get('email').invalid) {
                        <p class="text-xs text-danger text-center my-4">Must be a valid email address</p>
                    }
                    @if (emailError()) {
                        <p class="text-xs text-danger text-center my-4">{{ emailError() }}</p>
                    }
                </div>
    
                <div class="flex flex-col gap-1 mb-6">
                    <label>Full name</label>
                    <input name="fullName" type="fullName" pInputText required formControlName="fullName" placeholder="First name and last name">
                </div>
    
                <div class="flex flex-col gap-1 mb-6">
                    <label>Password</label>
                    <div class="relative mb-1">
                        <input [type]="passwordType()" pInputText autocomplete="current-password" required formControlName="password" placeholder="Enter a password" class="w-full">
                        <i class="absolute right-2 top-1/2 transform -translate-y-1/2 fa-solid text-grey-40" (click)="togglePasswordType()" [ngClass]="{'fa-eye': passwordType() === 'password', 'fa-eye-slash': passwordType() === 'text'}"></i>
                    </div>
                    <app-password-feedback [password]="password()"></app-password-feedback>
                </div>
    
                <div class="flex flex-col gap-1 mb-6">
                    <label>Organisation Name</label>
                    <input name="organisation" type="organisation" pInputText required formControlName="organisation" placeholder="Enter organisation name">
                </div>
    
                <div class="flex gap-[10px] mb-6">
                    <p-checkbox formControlName="terms" inputId="terms" value="terms" />
                    <label class="text-xs text-text-primary" for="terms">I acknowledge that I have read and accept the <a class="underline" target="_blank" href="https://coreo.io/terms/">Terms & Conditions</a> and I agree to the <a class="underline" target="_blank" href="https://coreo.io/privacy-policy/">Privacy Policy</a>.</label>
                </div>
    
                <div class="flex gap-[10px] mb-6">
                    <p-checkbox formControlName="marketingConsent" inputId="marketingConsent" value="marketingConsent" />
                    <label class="text-xs text-text-primary" for="marketingConsent">I am happy to be contacted with news and updates about the Coreo platform..</label>
                </div>

                <p-button type="submit" label="Create account" size="large" class="w-full" [disabled]="!signupForm.valid || !passwordValid()" [loading]="submitting()"></p-button>
    
                @if (signupError()) {
                    <p class="text-xs text-danger text-center my-4">{{ signupError() }}</p>
                }
            </form>
    
            <p class="text-xs text-text-primary text-center mb-4">Already have an account?</p>
    
            <a href="/signin" class="h-11 w-full border border-primary rounded-md flex items-center justify-center text-primary text-sm font-semibold">Sign in</a>
        </div>
    }
</div>