import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableLazyLoadEvent, TableModule, TableRowReorderEvent } from 'primeng/table';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { catchError, of, take } from 'rxjs';
import { ApiService } from 'src/app/core';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { debounce } from 'lodash';
import { NewCollectionItemModalComponent } from '../new-collection-item-modal/new-collection-item-modal.component';

interface CollectionItemsResponse {
    items: {
        id: number;
        key: string;
        value: string;
        mediaItems: {
            type: string;
            url: string;
            sort: number;
        }[]
    }[],
    itemCount: number;
    sortMode: SortMode;
    sortReverse: boolean;
    sortAttributeId: number;
}

interface FirstLoadResponse extends CollectionItemsResponse {
    attributes: {
        id: number;
        label: string;
    }[]
}

type CollectionTableItem = {
    id: number;
    key: string;
    value: string;
    avatar: string;
}

const sortModes = [
    'alphabetical',
    'manual',
    'attribute'
] as const;
type SortMode = typeof sortModes[number];

const initialLoadQuery = `query collectionItemsFirstLoad($projectId: Int!, $collectionId: Int!, $offset: Int!, $limit: Int!){
    project(id: $projectId){
        collections(where: { id: $collectionId }){
            items(offset: $offset, limit: $limit){
                id
                key
                value
                mediaItems{
                    type
                    url
                    sort
                }
            }
            itemCount
            sortMode
            sortReverse
            sortAttributeId
            attributes {
                id
                label
            }
        }
    }
}`;

const lazyLoadQuery = `query collectionItemsLazyLoad($projectId: Int!, $collectionId: Int!, $offset: Int!, $limit: Int!, $where: SequelizeJSON){
    project(id: $projectId){
        collections(where: {id: $collectionId }){
            items(offset: $offset, limit: $limit, where: $where){
                id
                key
                value
                mediaItems{
                    type
                    url
                    sort
                }
            }
            itemCount(where: $where)
            sortMode
            sortReverse
            sortAttributeId
        }
    }
}`;

@Component({
    selector: 'app-collection-table',
    templateUrl: 'collection-table.component.html',
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        TableModule,
        SelectButtonModule,
        AvatarModule,
        DropdownModule,
        IconFieldModule,
        InputIconModule,
        InputTextModule,
        ConfirmDialogModule,
        DynamicDialogModule
    ],
    providers: [
        ConfirmationService,
        DialogService
    ],
    standalone: true,
    styles: [
        `:host{ @apply block h-full w-full; }`
    ],
})

export class CollectionTableComponent implements OnInit, AfterViewInit {
    
    @Input() projectId: number;
    @Input() collectionId: number;
    @Input() title: string = 'Collection items';

    @Output() item: EventEmitter<number> = new EventEmitter();

    public isFirstLoad: boolean = true;
    public loading: boolean = false;
    public collection: CollectionTableItem[] = [];
    public itemCount: number = 0;
    public hasImages: boolean = false;
    
    public first: number = 0;
    public rows: number = 10;
    public canReorder: boolean = false;

    public search: string = '';
    public sortOptions: {label: string; value: SortMode, disabled?: boolean}[];
    public sortMode: SortMode;
    public sortAttributeId: number;
    public sortReverse: boolean = false;
    public showAttributeDropdown: boolean = false;
    public attributeDropdownOptions: {id: number, label: string}[];

    private dialogRef: DynamicDialogRef | undefined;

    searchInput = viewChild<ElementRef>('searchInput');

    constructor(
        private apiService: ApiService,
        private confirmationService: ConfirmationService,
        private dialogService: DialogService
    ) { }

    ngOnInit() {
        // console.log('CollectionDetailComponent', this.projectId, this.collectionId);/
    }
        
    ngAfterViewInit(): void {
        /** Prevent dropping rows into the search input when reordering - results in a 'b' in the search box if allowed because of this: https://github.com/primefaces/primeng/blob/3a2f6c00d38feb1db72cb0ac02ca1fdba1d180e1/src/app/components/table/table.ts#L1945 */
        this.searchInput().nativeElement.addEventListener('drop', function(e) {
            e.preventDefault();
            // console.log("Drop event blocked!");
        });
    }

    public loadCollection($event: TableLazyLoadEvent = null) {
        this.loading = true;
        // store in case of reorder error and we need to reload the collection
        if ($event) {   
            this.first = $event.first
            this.rows = $event.rows;
        }

        const where = {and: [
            { value:  { iLike: `%${this.search}%` }}
        ]};

        const input = { 
            projectId: this.projectId, 
            collectionId: this.collectionId, 
            offset: this.first, 
            limit: this.rows, 
            where 
        };

        const query = this.isFirstLoad ? initialLoadQuery : lazyLoadQuery;

        this.apiService.graphql<{ project: { collections: any[] } }>(query, input).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {         
                const result = res.project.collections[0] as CollectionItemsResponse;
                const { items, itemCount, sortMode, sortAttributeId } = result;
                this.collection = items.map(i => {
                    const images = i.mediaItems.filter(mi => mi.type.startsWith('image')).sort((a, b) => a.sort - b.sort);
                    return {
                        id: i.id,
                        key: i.key,
                        value: i.value,
                        avatar: images.length > 0 ? images[0].url : null
                    }
                });
                this.itemCount = itemCount;
                this.hasImages = items.reduce((acc, item) => {
                    const images = item.mediaItems.filter(mi => mi.type.startsWith('image'));
                    if (images.length > 0) {
                        acc.push(images[0]);
                    }
                    return acc;
                }, []).length > 0;
                
                this.sortMode = sortMode;
                this.sortReverse = this.sortReverse;
                this.sortAttributeId = sortAttributeId;
                
                if (this.isFirstLoad) {
                    const firstLoadResult = res.project.collections[0] as FirstLoadResponse;
                    const { attributes } = firstLoadResult;
                    this.isFirstLoad = false;
                    this.sortOptions = [
                        { label: 'Alphabetically', value: 'alphabetical' },
                        { label: 'Manually', value: 'manual' },
                        { label: 'By Attribute', value: 'attribute', disabled: attributes.length < 1 }
                    ];
                    this.attributeDropdownOptions = attributes;
                }
            }
            this.loading = false;
        });
    }

    public newItem() {
        this.dialogRef = this.dialogService.open(NewCollectionItemModalComponent, { 
            header: 'New collection item',
            data: { collectionId: this.collectionId },
            width: '420px'
        });

        this.dialogRef.onClose.subscribe((itemAdded: boolean) => {
            if (itemAdded) {
                this.loadCollection();
            }
        });
    }

    public rowSelect(item: CollectionTableItem) {
        this.item.emit(item.id);
    }

    public clearSearch($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.search = '';
        this.handleSearch();
    }

    public debouncedSearch = debounce(this.handleSearch, 500);

    private handleSearch() {
        this.first = 0;
        this.rows = 10;

        this.loadCollection();
    }

    public reverse() {
        this.sortReverse = !this.sortReverse;
        this.updateSort();
    }

    public updateSort() {
        this.loading = true;

        const query = `mutation updateCollectionSortOrder($input: CollectionUpdateInput!){
            updateCollection(input: $input){
                id
            }
        }`;

        const input = { 
            id: this.collectionId,
            sortMode: this.sortMode,
            sortReverse: this.sortReverse,
            sortAttributeId: this.sortAttributeId
        };

        this.apiService.graphql<{ updateCollection: { collection: { id: number } } }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                this.loading = false;
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                // success toast
                this.loadCollection();
            }
        });
    }

    public reorderRows($event: TableRowReorderEvent) {
        if ($event.dragIndex === $event.dropIndex) {
            return;
        }

        const query = `mutation reorderCollectionItems($input: ItemsUpdateInput!){
            updateCollectionItems(input: $input)
        }`;

        const input = {
            /** Add first value to index to allow for page offset when updating the sort order */
            items: this.collection.map((item, index) => ({id: item.id, sort: index + this.first}))
        };
        
        this.apiService.graphql<{ updateCollectionItems: boolean }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                /** Reload collection as local order will be wrong */
                this.loadCollection();
                return of(null);
            })
        ).subscribe();
    }

    public deleteCollectionItemCheck($event: Event, item: CollectionTableItem) {
        $event.preventDefault();
        $event.stopPropagation();

        this.confirmationService.confirm({
            defaultFocus: 'none',
            message: 'Are you sure you want to delete this item?',
            target: $event.target,
            header: 'Delete item',
            rejectLabel: 'Cancel',
            rejectIcon: 'none',
            rejectButtonStyleClass: 'p-button p-button-lg p-button-outlined',
            acceptLabel: 'Delete',
            acceptIcon: 'none',
            acceptButtonStyleClass: 'p-button p-button-lg p-button-danger',
            accept: () => {
                this.deleteCollectionItem(item);
            }
        });
    }

    private deleteCollectionItem(item: CollectionTableItem) {
        this.loading = true;

        const query = `mutation deleteCollectionItem($input: ItemDeleteInput!){
            deleteCollectionItem(input: $input)
        }`;

        const input = {
            id: item.id
        };

        this.apiService.graphql<{ deleteCollectionItem: number }>(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                this.loading = false;
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                // success toast
                this.loadCollection();
            }
        });
    }

    public refresh(full: boolean = false) {
        if (full) {
            this.isFirstLoad = true;
        }

        this.loadCollection();
    }
}