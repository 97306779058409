import * as Sentry from '@sentry/browser';
import { getProjectRole, getProjectId, getProjectOrganisationId, getProjectAccessMode, getProjectSlug, getProjectName, getProjectShareableLink, getProjectAllowContributors, getOrganisationRole, getProjectBespoke, getOrganisationMemberships, getProjectHasEmptySeats, getProjectAccessCodeForPresentation } from '../../store/selectors';
import { jobFieldsFragment } from 'src/app/core/services/jobs.service';

export default class ProjectUsersController {
    static $inject = ['$mdDialog', '$ngRedux', '$rootScope', 'toastr', '$timeout', '$sce', 'ProjectsService', 'Tabulator', 'CoreoAPI', 'ProjectActions', 'apiHostname', 'jobsService'];
    constructor($mdDialog, $ngRedux, $rootScope, toastr, $timeout, $sce, ProjectsService, Tabulator, CoreoAPI, ProjectActions, apiHostname, jobsService) {
        this.ProjectsService = ProjectsService;
        this.toastr = toastr;
        this.$rootScope = $rootScope;
        this.$timeout = $timeout;
        this.$mdDialog = $mdDialog;
        this.Tabulator = Tabulator;
        this.CoreoAPI = CoreoAPI;
        this.apiHostname = apiHostname;
        this.$sce = $sce;

        this.usersCount = null;
        this.projectUsersWhereString = '';
        this.projectUsersOrderString = "reverse:createdAt";
        this.$ngRedux = $ngRedux;
        this.ProjectActions = ProjectActions;
        this.redux = {};
        this.filtering = false;

        const state = $ngRedux.getState();
        this.projectId = getProjectId(state);
        this.projectRole = getProjectRole(state);
        this.organisationRole = getOrganisationRole(state);
        this.organisationId = getProjectOrganisationId(state);
        this.projectAccessMode = getProjectAccessMode(state);
        this.projectSlug = getProjectSlug(state);
        this.projectName = getProjectName(state);
        this.shareableLink = getProjectShareableLink(state);
        this.accessCode = getProjectAccessCodeForPresentation(state);
        this.allowContributors = getProjectAllowContributors(state);
        this.organisationMemberships = getOrganisationMemberships(state);
        this.projectIsBespoke = getProjectBespoke(state);
        this.projectHasEmptySeats = getProjectHasEmptySeats(state)
        this.isAdmin = getProjectRole(state) === 'admin';

        this.jobsService = jobsService;

        this.roles = [{
            id: 'admin',
            title: 'Admin'
        }, {
            id: 'moderator',
            title: 'Moderator'
        }, {
            id: 'member',
            title: 'Member'
        }, {
            id: 'contributor',
            title: 'Contributor'
        }];

        this.statuses = [{
            id: 'APPROVED',
            title: 'Approved'
        }, {
            id: 'PENDING_APPROVAL',
            title: 'Pending'
        }, {
            id: 'REJECTED',
            title: 'Rejected'
        }];

    }

    $onInit() {

        const columns = [
            { title: 'Name', field: 'user.displayName', headerFilter: true },
            { title: 'Username', field: 'user.username', headerFilter: true },
            {
                title: 'Role',
                field: 'role',
                headerFilter: 'list',
                headerFilterParams: {
                    values: {
                        "member": "Member",
                        "moderator": "Moderator",
                        "admin": "Admin",
                        "contributor": "Contributor"
                    },
                    clearable: true
                },
                formatter: 'lookup',
                formatterParams: {
                    "member": "Member",
                    "moderator": "Moderator",
                    "admin": "Admin",
                    "contributor": "Contributor"
                }
            }
        ];

        if (this.projectAccessMode !== 'OPEN') {
            columns.push({
                title: 'Status',
                field: 'status',
                headerFilter: 'select',
                headerFilterPlaceholder: 'Filter by status...',
                headerFilterParams: {
                    "": "",
                    "APPROVED": "Approved",
                    "PENDING_APPROVAL": "Pending Approval",
                    "REJECTED": "Rejected"
                },
                formatter: 'lookup',
                formatterParams: {
                    "APPROVED": "Approved",
                    "PENDING_APPROVAL": "Pending Approval",
                    "REJECTED": "Rejected"
                }
            })
        }

        if (this.isAdmin) {
            columns.splice(2, 0, {
                title: 'Email', field: 'user.email', headerFilter: true
            });
        }

        this.table = this.Tabulator.createTable('#project-users-table', {
            layout: "fitColumns",
            height: '100%',
            index: 'userId',
            paginationSize: 50,
            selectable: 1,
            progressiveLoadDelay: 500,
            progressiveLoad: "scroll", //enable progressive loading
            progressiveLoadScrollMargin: 1000,
            initialSort: [],
            columns,
            ajaxURL: this.apiHostname,
            ajaxRequestFunc: (_url, _config, params) => {
                let { filter, sort, page, size } = params;
                page = page ?? 1;
                size = size ?? 10;
                this.filtering = false;
                const projectUserWhereParams = filter.filter(f => f.field.substring(0, 4) !== 'user').reduce((acc, filter) => {
                    if (filter.value && filter.value[0]) {
                        this.filtering = true;
                        acc.push(filter.field + ':' + filter.value);
                    }
                    return acc;
                }, []);
                const userWhereParams = filter.filter(f => f.field.substring(0, 4) === 'user').reduce((acc, filter) => {
                    acc.push(`${filter.field.split('.')[1]}: { iLike: "%${filter.value}%"}`);
                    this.filtering = true;
                    return acc;
                }, []);
                //remove deleted users from query
                userWhereParams.push(`deletedAt: { eq: null }`)
                const projectUserWhere = userWhereParams.length + projectUserWhereParams.length ? `where: {${projectUserWhereParams.join(',')} ${projectUserWhereParams.length ? ', ' : ''}user:{${userWhereParams.join(',')}}},` : '';
                let order = `reverse:createdAt`;
                if (sort && sort.length) {
                    const { field, dir } = sort[0];
                    order = `${dir === 'desc' ? 'reverse' : ''}${field}`;
                }
                this.projectUsersOrderString = order;
                this.projectUsersWhereString = projectUserWhere;

                const query = `{data: project(id:${this.projectId}){
          projectUsers(order:"${order}",${projectUserWhere}, offset: ${size * (page - 1)}, limit: ${size}){
            result {
              data,
              marketingConsent,
              moderator,
              role,
              status,
              createdAt,
              userId,
              user{
                id,
                displayName,
                username,
                ${this.isAdmin ? 'email, id, provider, verified' : 'id'}
              }
            } count}}}`;
                return this.CoreoAPI.query(query).then(({ data }) => {
                    this.usersCount = data.projectUsers.count;
                    return {
                        data: data.projectUsers.result,
                        last_page: Math.ceil(this.usersCount / size)
                    }
                });
            },
            filterMode: "remote",
            sortMode: "remote"
        });

        this.table.on('rowClick', (_e, row) => {
            this.$timeout(() => this.selectProjectUser(row.getData()), 0);
        });
    }

    addUser(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('!raw-loader!./project-users-add-user.modal.html').default,
            resolve: {
                // Special Resolver that find organisation users that are not already part of this project
                users: ['CoreoAPI', (CoreoAPI) => {
                    const query = `query getProjectAvailableUsers($projectId: Int!){
            project(id: $projectId){
              availableInviteeUsers{
                id,
                username,
                displayName
              }
            }
          }`;
                    return CoreoAPI.query(query, {
                        variables: {
                            projectId: this.projectId
                        }
                    }).then(result => {
                        const { project: { availableInviteeUsers } } = result;
                        return availableInviteeUsers;
                    });
                }]
            },
            controllerAs: 'ctrl',
            controller: class ProjectUserAddController {
                static $inject = ['$mdDialog', 'users'];
                constructor($mdDialog, users) {
                    this.users = users;
                    this.$mdDialog = $mdDialog;
                    this.userAvatar = '../../assets/images/user.svg';
                    this.user = null;
                }

                ok() {
                    this.$mdDialog.hide({
                        user: this.user,
                        role: this.role
                    });
                }

                cancel() {
                    this.$mdDialog.cancel();
                }
            }
        }).then((result) => {
            const { user, role } = result;
            this.$ngRedux.dispatch(this.ProjectActions.addProjectUser(user.id, role)).then(result => {
                //User may already be in table as contributor
                const existsAsContributor = this.table.searchRows("userId", "=", result.userId)
                let tableUpdatePromise = existsAsContributor ? this.table.updateData([result]) : this.table.addData([result], true);

                return tableUpdatePromise.then(() => {
                    this.table.selectRow(result.userId);
                    this.projectUser = result;
                    this.usersCount++;
                })
            });
        }, angular.noop);
    }

    removeUser(ev) {
        this.$mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: ev,
            clickOutsideToClose: true,
            template: require('!raw-loader!./project-users-remove-user.modal.html').default,
            controllerAs: 'ctrl',
            controller: class ProjectUserRemoveController {
                static $inject = ['$mdDialog'];
                constructor($mdDialog) {
                    this.$mdDialog = $mdDialog;
                }

                ok() {
                    this.$mdDialog.hide();
                }

                cancel() {
                    this.$mdDialog.cancel();
                }
            }
        }).then(() => {
            const userId = this.projectUser && this.projectUser.user && this.projectUser.user.id;

            this.$ngRedux.dispatch(this.ProjectActions.deleteProjectUser(userId)).then(wasRemoved => {
                if (wasRemoved) {
                    this.table.deleteRow(userId);
                    this.projectUser = null;
                    this.usersCount--;
                } else {
                    this.projectUser.role = 'contributor';
                    this.table.updateData([this.projectUser]);
                }
            });
        }, angular.noop);
    }

    updateUserRole(ev) {
        if (this.projectUserRole === 'contributor') {
            this.$mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: true,
                template: require('!raw-loader!./project-users-upgrade-contributor.modal.html').default,
                locals: {
                    upgradeRole: this.projectUser.role
                },
                controllerAs: 'ctrl',
                controller: class ProjectUserRemoveController {
                    static $inject = ['$mdDialog', 'upgradeRole'];
                    constructor($mdDialog, upgradeRole) {
                        this.$mdDialog = $mdDialog;
                        this.upgradeRole = upgradeRole;
                    }

                    ok() {
                        this.$mdDialog.hide();
                    }

                    cancel() {
                        this.$mdDialog.cancel();
                    }
                }
            }).then(() => {
                this.$ngRedux.dispatch(this.ProjectActions.updateProjectUserRole(this.projectUser.user.id, this.projectUser.role)).then(result => {
                    this.table.updateData([this.projectUser]);
                }, err => {
                    console.warn(err);
                    this.projectUser.role = this.projectUserRole;
                });
            }, () => {
                this.projectUser.role = this.projectUserRole;
            });
        } else {
            this.$ngRedux.dispatch(this.ProjectActions.updateProjectUserRole(this.projectUser.user.id, this.projectUser.role)).then(result => {
                this.table.updateData([this.projectUser]);
            }, err => {
                console.warn(err);
                this.projectUser.role = this.projectUserRole;
            });
        }
    }

    updateUserStatus() {
        const status = this.projectUser.status;
        const userId = this.projectUser && this.projectUser.user && this.projectUser.user.id;

        this.ProjectsService.updateProjectUserStatus(this.projectId, userId, status).then(() => {
            this.projectUser.status = status;
            this.table.updateData([this.projectUser]);
            this.toastr.success('User status updated')
        }, err => {
            this.toastr.error('User status failed to update');
            Sentry.captureException(err);
        });
    };

    updateAccessControl() {
        this.ProjectsService.update(this.projectId, {
            accessMode: this.projectAccessMode
        });
    }

    selectProjectUser(projectUser) {
        this.projectUser = projectUser;
        this.projectUserRole = projectUser.role;

        try {
            this.projectUserData = typeof projectUser.data === 'string' ? JSON.parse(projectUser.data) : projectUser.data;
        } catch (e) {
            this.projectUserData = {};
            console.log('Error parsing data', e, projectUser);
        }
    };

    createUsersExport() {
        const mutation = `mutation CoreoAAExportProjectUsers($input: ProjectUsersExportInput!){
            exportProjectUsers(input: $input){
                ...jobFields
            }
        }
        ${jobFieldsFragment}
`;
        return this.CoreoAPI.gql(mutation, {
            input: {
                projectId: this.projectId,
                order: this.projectUsersOrderString
            }
        }).then((response) => {
            this.jobsService.addJob(response.exportProjectUsers);
        });
    }

    shareLinkClicked() {
        this.toastr.success('Link copied to clipboard');
    }

    accessCodeClicked() {
        this.toastr.success('Access code copied to clipboard');
    }

    shareQrClicked(ev) {
        import(/* webpackChunkName: "qrcode" */'qrcode').then(({ default: QRCode }) => {
            QRCode.toDataURL(this.shareableLink, {
                width: 480,
                height: 480,
            }, (err, url) => {
                const safe = this.$sce.trustAsResourceUrl(url);
                const downloadName = _.snakeCase(this.projectName + 'QrCode');
                this.$mdDialog.show({
                    parent: angular.element(document.body),
                    targetEvent: ev,
                    clickOutsideToClose: true,
                    template: require('!raw-loader!./project-users-qr.modal.html').default,
                    controllerAs: 'ctrl',
                    controller: class ProjectUserQRController {
                        static $inject = ['$mdDialog'];
                        constructor($mdDialog) {
                            this.$mdDialog = $mdDialog;
                            this.url = safe;
                            this.downloadName = downloadName;
                        }

                        ok() {
                            this.$mdDialog.hide();
                        }

                        cancel() {
                            this.$mdDialog.cancel();
                        }
                    }
                });
            });
        });
    }
}