import angular from 'angular';
import { snakeCase } from 'lodash';
import CoreoAPIService from '../../main/coreoApi';
import { getProjectAttributes, getProjectCollection, getProjectCollections, getProjectId, getProjectOrganisationId } from '../selectors';

function projectActions(ProjectService, ProjectPagesService, ProjectMediaService, ProjectMapsService, $rootScope, CoreoAPI: CoreoAPIService, ProjectDependencyErrorService) {

    const loadProject = slug => (dispatch) => {
        dispatch({
            type: 'LOAD_PROJECT',
            slug
        });

        return ProjectService.load(slug).then(project => {
            dispatch({
                type: 'LOAD_PROJECT_SUCCESS',
                project
            });
            return project;
        }, error => {
            dispatch({
                type: 'LOAD_PROJECT_FAILURE',
                error
            });
            return null;
        });
    };

    const loadProjectStats = id => (dispatch) => {
        dispatch({
            type: 'LOAD_PROJECT_STATS',
            id
        });

        return ProjectService.loadStats(id).then(stats => {
            dispatch({
                type: 'LOAD_PROJECT_STATS_SUCCESS',
                stats
            });
            return stats;
        }, error => dispatch({
            type: 'LOAD_PROJECT_STATS_FAILURE',
            error
        }));
    }


    const createForm = (name, title, sort) => (dispatch) => {
        return ProjectService.createForm(name, title, sort).then(form => {
            dispatch({
                type: 'CREATE_FORM_SUCCESS',
                form
            });
            return form;
        }).catch(err => {
            dispatch({
                type: 'CREATE_FORM_ERROR',
                err,
                toastError: 'Failed to create form'
            });
            throw err;
        });
    };

    const deleteForm = id => (dispatch) => {
        dispatch({
            type: 'DELETE_FORM',
            id
        });
        return ProjectService.deleteForm(id)
            .then(() => dispatch({
                type: 'DELETE_FORM_SUCCESS',
                id
            }));
    };

    const updateForms = forms => (dispatch) => {
        return ProjectService.sortForms(forms)
            .then(() => dispatch({
                type: 'UPDATE_FORMS_SUCCESS',
                forms
            }));
    };

    const updateForm = form => async (dispatch) => {

        dispatch({
            type: 'UPDATE_FORM',
            id: form.id
        });

        const mutation = `mutation AAUpdateForm($input: SurveyUpdateInput!){
            updateSurvey(input: $input){
                id
            }
        }`;
        await CoreoAPI.gql(mutation, { input: form });

        dispatch({
            type: 'UPDATE_FORM_SUCCESS',
            form
        });
    };

    const pollCollection = (projectId, collectionId, dispatch, getState) => {
        const interval = setInterval(() => {
            ProjectService.getCollection(projectId, collectionId).then(collection => {
                const { attributes, ...updatedCollection } = collection;
                const existingAttributeIds = getProjectAttributes(getState()).filter(a => a.parentCollectionId === collectionId).map(a => a.id);
                const newAttributes = attributes.filter(a => !existingAttributeIds.includes(a.id));

                dispatch({ // <-- No condition?
                    type: 'UPDATE_COLLECTION_JOB_SUCCESS',
                    collection: updatedCollection,
                    newAttributes
                });

                if (updatedCollection.status !== 'processing') {
                    /// Dispatch something
                    $rootScope.$broadcast('jobs:refresh');
                    clearInterval(interval);
                }
            })
        }, 1000);
    }

    const createCollection = (collection, files = {}) => (dispatch, getState) => {

        const projectId = collection.projectId = getProjectId(getState());

        dispatch({
            type: 'CREATE_COLLECTION'
        });

        return ProjectService.createCollection(collection, files).then(collection => {
            const collectionId = collection.id;
            if (collection.status === 'processing') {
                pollCollection(projectId, collectionId, dispatch, getState);
            }
            dispatch({
                type: 'CREATE_COLLECTION_SUCCESS',
                collection
            });
            return collection;
        }, err => {
            dispatch({
                type: 'CREATE_COLLECTION_FAILURE',
                toast: {
                    type: 'error',
                    message: 'Error creating collection'
                },
                err
            });
            throw err;
        });
    };

    const updateCollectionSettings = collection => async dispatch => {
        dispatch({
            type: 'UPDATE_COLLECTION',
            id: collection.id
        });

        const mutation = `mutation AAUpdateCollectionSettings($input: CollectionUpdateInput!){
            updateCollection(input: $input){
                id
            }
        }`;
        await CoreoAPI.gql(mutation, { input: collection });

        dispatch({
            type: 'UPDATE_COLLECTION_SUCCESS',
            collection
        });
    }
    /**
     * Action that makes the required changes between the incoming collection and the one currently in the store
     */
    const updateCollection = collection => (dispatch, getState) => {
        const collections = getProjectCollections(getState());
        const existingCollection = collections.find(c => c.id === collection.id);
        if (typeof existingCollection === 'undefined') {
            return dispatch({
                type: 'UPDATE_COLLECTION_FAILURE',
                err: 'collectionNotFound'
            });
        }

        dispatch({
            type: 'UPDATE_COLLECTION'
        });


        const existingItems = existingCollection.items;

        let promises = [];
        const collectionDiff: any = {};
        let sortChanged = false;
        if (collection.name !== existingCollection.name) {
            collectionDiff.name = collection.name;
        }
        if (collection.sortMode !== existingCollection.sortMode) {
            collectionDiff.sortMode = collection.sortMode;
            sortChanged = true;
        }
        if (collection.sortAttributeId !== existingCollection.sortAttributeId) {
            collectionDiff.sortAttributeId = collection.sortAttributeId;
            sortChanged = true;
        }
        if (collection.sortReverse !== existingCollection.sortReverse) {
            collectionDiff.sortReverse = collection.sortReverse;
            sortChanged = true;
        }

        if (Object.keys(collectionDiff).length > 0) {
            promises.push(ProjectService.updateCollection(collection.id, collectionDiff));
        }


        // Find items that have been lost
        const itemsToDelete = existingItems.filter(existingItem => typeof collection.items.find(newItem => newItem.key === existingItem.key) === 'undefined');
        promises = promises.concat(itemsToDelete.map(item => ProjectService.deleteCollectionItem(item.id)));

        // Add new items
        const newItems = collection.items.filter(i => !i.id || i.id < 0).map(item => ({
            ...item,
            collectionId: collection.id,
            key: item.key || snakeCase(item.value)
        }));

        promises = promises.concat(newItems.map(newItem => ProjectService.createCollectionItem(newItem).then(i => newItem.id = i.id)));

        // Updated items
        const updatedItems = collection.items.filter(item => {
            delete item.mediaItems; // these cannot be updated by / are not managed by this mutation
            if (newItems.map(i => i.id).indexOf(item.id) !== -1) {
                return false;
            }
            const existing = existingItems.find(e => e.id === item.id);
            return (existing.value !== item.value) || (existing.sort !== item.sort);
        });
        promises = [
            ...promises,
            updatedItems.map(item => ProjectService.updateCollectionItem({ ...item }))
        ];

        return Promise.all(promises).then(() => {
            if (sortChanged) {
                dispatch(refreshCollection(collection.id));
            }
            return dispatch({
                type: 'UPDATE_COLLECTION_SUCCESS',
                collection
            });
        }, err => {
            let toast;
            if (err.code === 'ModelDependencyError') {
                ProjectDependencyErrorService.display(err);
            } else {
                toast = {
                    type: 'error',
                    message: 'Failed to update collection'
                }
            }

            dispatch({
                type: 'UPDATE_COLLECTION_FAILURE',
                err,
                toast
            })
        });
    };

    const refreshCollection = (id) => (dispatch, getState) => {
        return ProjectService.getCollection(getProjectId(getState()), id).then(collection => {
            dispatch({
                type: 'REFRESH_COLLECTION',
                collection
            })
        })
    }

    const updateCollectionItems = (id, items) => dispatch => {
        dispatch({
            type: 'UPDATE_COLLECTION_ITEMS'
        });

        return ProjectService.updateCollectionItems(items).then(() => dispatch({
            type: 'UPDATE_COLLECTION_ITEMS_SUCCESS',
            id,
            items,
            toast: 'Items updated'
        }), err => dispatch({
            type: 'UPDATE_COLLECTION_ITEMS_FAILURE',
            id,
            err,
            toast: {
                type: 'error',
                message: 'Failed to update items'
            }
        }));
    };

    const importCollectionItems = (collectionId, update, files) => (dispatch, getState) => {

        const projectId = getProjectId(getState());

        dispatch({
            type: 'IMPORT_COLLECTION_ITEMS',
            collectionId,
            update,
            files
        });

        return ProjectService.importCollectionItems(collectionId, update, files).then((result) => {
            dispatch({
                type: 'IMPORT_COLLECTION_ITEMS_SUCCESS'
            });
            pollCollection(projectId, collectionId, dispatch, getState);
        }, err => dispatch({
            type: 'IMPORT_COLLECTION_ITEMS_FAILURE',
            err
        }));
    }

    const deleteCollection = id => dispatch => {
        dispatch({
            type: 'DELETE_COLLECTION'
        });

        return ProjectService.deleteCollection(id).then(() => {
            dispatch({
                type: 'DELETE_COLLECTION_SUCCESS',
                id,
                toast: 'Collection Deleted'
            });
            return true;
        }, err => {
            if (err.code === 'ModelDependencyError') {
                ProjectDependencyErrorService.display(err);
            } else {
                dispatch(
                    {
                        type: 'DELETE_COLLECTION_FAILURE',
                        err,
                        toast: {
                            type: 'error',
                            message: 'There was an error deleting the collection'
                        }
                    }
                )
            }
            return false;
        }
        );
    };

    const deleteCollectionItem = (collectionId, itemId) => dispatch => {
        dispatch({
            type: 'DELETE_COLLECTION_ITEM',
            collectionId,
            itemId
        });

        return ProjectService.deleteCollectionItem(itemId).then(() => {
            dispatch({
                type: 'DELETE_COLLECTION_ITEM_SUCCESS',
                collectionId,
                itemId
            });
            return true;
        }, err => {
            if (err.code === 'ModelDependencyError') {
                ProjectDependencyErrorService.display(err);
            } else {
                dispatch({
                    type: 'DELETE_COLLECTION_ITEM_FAILURE',
                    collectionId,
                    itemId,
                    err
                });
            }
            return false;
        });
    };

    const deleteProject = () => (dispatch, getState) => {
        dispatch({
            type: 'DELETE_PROJECT'
        });
        const projectId = getProjectId(getState())

        return ProjectService.deleteProject(projectId).then(() => dispatch({
            type: 'DELETE_PROJECT_SUCCESS',
            projectId,
            toast: 'Project Deleted'
        })
            , err => dispatch({
                type: 'DELETE_PROJECT_FAILURE',
                err
            }));
    };

    const loadCollection = (id) => (dispatch, getState) => {
        const state = getState();
        const collection = getProjectCollection(id)(state);
        if (typeof collection.items !== 'undefined') {
            return collection;
        }

        return ProjectService.getCollection(collection.projectId, id).then(collection => {
            dispatch({
                type: 'LOAD_COLLECTION',
                collection
            });
            return collection;
        });
    }

    const createAttribute = (attribute) => (dispatch, getState) => {
        dispatch({
            type: 'CREATE_ATTRIBUTE'
        });

        const projectId = getProjectId(getState())

        return ProjectService.addAttribute({ ...attribute, projectId }).then((result) => dispatch({
            type: 'CREATE_ATTRIBUTE_SUCCESS',
            attribute: result,
            toast: 'Item created'
        }), err => dispatch({
            type: 'CREATE_ATTRIBUTE_FAILURE',
            err,
            toast: {
                type: 'error',
                message: 'Failed to create attribute'
            }
        }));
    };

    const updateAttribute = (attribute) => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_ATTRIBUTE'
        });

        return ProjectService.updateAttribute(attribute).then((result) => dispatch({
            type: 'UPDATE_ATTRIBUTE_SUCCESS',
            attribute,
            toast: 'Attribute Updated'
        }), err => dispatch({
            type: 'UPDATE_ATTRIBUTE_FAILURE',
            err,
            toast: {
                type: 'error',
                message: 'Failed to update attribute'
            }
        })).then(() => {
            if (attribute.parentCollectionId) {
                const projectId = getProjectId(getState());
                pollCollection(projectId, attribute.parentCollectionId, dispatch, getState);
            }
        });
    };

    const deleteAttribute = (id) => dispatch => {
        dispatch({
            type: 'DELETE_ATTRIBUTE'
        });

        return ProjectService.deleteAttribute(id).then(() => dispatch({
            type: 'DELETE_ATTRIBUTE_SUCCESS',
            id
        }), err => dispatch({
            type: 'DELETE_ATTRIBUTE_FAILURE',
            err,
            id
        }));
    };

    const createCollectionItem = (item) => dispatch => {
        dispatch({
            type: 'CREATE_COLLECTION_ITEM',
            item
        });

        return ProjectService.createCollectionItem(item).then((item) => {
            dispatch({
                type: 'CREATE_COLLECTION_ITEM_SUCCESS',
                item
            });
            return item;
        }, err => dispatch({
            type: 'CREATE_COLLECTION_ITEM_FAILURE',
            err,
            toast: {
                type: 'error',
                message: 'Failed to create collection item'
            }
        }));
    };

    const updateCollectionItem = (collectionId, item) => dispatch => {
        dispatch({
            type: 'UPDATE_COLLECTION_ITEM',
            item,
            collectionId
        });
        return ProjectService.updateCollectionItem({ ...item }).then(() => {
            dispatch({
                type: 'UPDATE_COLLECTION_ITEM_SUCCESS',
                item,
                toast: 'Item updated',
                collectionId
            });
            return item;
        }, err => dispatch({
            type: 'UPDATE_COLLECTION_ITEM_FAILURE',
            err,
            collectionId,
            toast: {
                type: 'error',
                message: 'Failed to update collection item'
            }
        }));
    };

    const loadCollectionItem = (collectionId, itemId) => (dispatch, getState) => {

        const projectId = getProjectId(getState());

        return ProjectService.getCollectionItem(projectId, collectionId, itemId).then(item => {
            return item;
        });
    };

    const loadCollectionItems = (collectionId, includeData = false) => (dispatch, getState) => {
        const state = getState();
        const projectId = getProjectId(state);

        return ProjectService.getCollectionItems(projectId, collectionId, includeData).then(items => {
            dispatch({
                type: 'LOAD_PROJECT_COLLECTION_ITEMS',
                collectionId,
                items
            });
            return items;
        });
    };

    const updateProject = (project) => (dispatch) => {
        return ProjectService.updateProject(project).then((updatedProject) => dispatch({
            type: 'UPDATE_PROJECT_SUCCESS',
            project: Object.assign(project, { imageUrl: updatedProject.imageUrl, hasEmptySeats: updatedProject.hasEmptySeats }),
            toast: 'Project updated'
        }), err => dispatch({
            type: 'UPDATE_PROJECT_FAILURE',
            err,
            toastError: 'Failed to update project'
        }));
    }

    const createCredential = credential => (dispatch, getState) => {
        const projectId = getProjectId(getState());
        return ProjectService.createCredential({
            ...credential,
            projectId
        }).then((result) => dispatch({
            type: 'CREATE_CREDENTIAL_SUCCESS',
            credential: result,
            toast: 'Credential Created'
        }), err => dispatch({
            type: 'CREATE_CREDENTIAL_FAILURE',
            err,
            toastError: 'Failed to create credential'
        }));
    };

    const updateCredential = credential => (dispatch) => {
        return ProjectService.updateCredential(credential).then(() => dispatch({
            type: 'UPDATE_CREDENTIAL_SUCCESS',
            credential,
            toast: 'Credential Updated'
        }), err => dispatch({
            type: 'UPDATE_CREDENTIAL_FAILURE',
            err,
            toastError: 'Failed to update credential'
        }));
    };


    const addProjectPage = page => (dispatch) => {
        dispatch({
            type: 'ADD_PROJECT_PAGE',
        })
        return ProjectPagesService.createPage(page).then((result) => {
            page.id = result;

            dispatch({
                type: 'ADD_PROJECT_PAGE_SUCCESS',
                toast: 'New page created',
                page
            })
            return page;
        }, err => {
            return dispatch({
                type: 'ADD_PROJECT_PAGE_FAILURE',
                err,
                toastError: 'Unable to create new page'
            })
        });
    }

    const deleteProjectPage = id => (dispatch) => {
        dispatch({
            type: 'DELETE_PROJECT_PAGE',
        })
        return ProjectPagesService.deletePage(id).then((_result) => {
            return dispatch({
                type: 'DELETE_PROJECT_PAGE_SUCCESS',
                toast: 'Page Deleted',
                id
            })
        }, err => {
            return dispatch({
                type: 'DELETE_PROJECT_PAGE_FAILURE',
                err,
                toastError: 'Unable to delete page'
            })
        });
    };

    const reorderProjectPages = pageOrderMap => dispatch => {
        return ProjectPagesService.updatePages(pageOrderMap).then(() =>
            dispatch({
                type: 'REORDER_PROJECT_PAGES',
                order: pageOrderMap
            })
        );
    };

    const deleteItemMediaItem = (collectionId, itemId, mediaItemId) => (dispatch) => {
        dispatch({
            type: 'DELETE_COLLECTION_ITEM_MEDIA',
        })
        return ProjectMediaService.deleteMediaItems([mediaItemId]).then(() =>
            dispatch({
                type: 'DELETE_COLLECTION_ITEM_MEDIA_SUCCESS',
                collectionId,
                itemId,
                mediaItemId
            })
            , err => {
                dispatch({
                    type: 'DELETE_COLLECTION_ITEM_MEDIA_FAILURE',
                    err
                })
            })
    }

    const createItemMediaItem = (collectionId, itemId, mediaItem) => (dispatch, getState) => {
        const projectId = getProjectId(getState());
        dispatch({
            type: 'CREATE_COLLECTION_ITEM_MEDIA',
        })
        return ProjectMediaService.createItemMediaItem(mediaItem, itemId, projectId, mediaItem.file).then(result =>
            dispatch({
                type: 'CREATE_COLLECTION_ITEM_MEDIA_SUCCESS',
                collectionId,
                itemId,
                mediaItem: result
            })
            , err => {
                dispatch({
                    type: 'CREATE_COLLECTION_ITEM_MEDIA_FAILURE',
                    err
                })
            })
    }

    const updateItemMediaItem = (collectionId, itemId, mediaItemId, mediaItemUpdate) => (dispatch) => {
        dispatch({
            type: 'UPDATE_COLLECTION_ITEM_MEDIA',
        })
        return ProjectMediaService.updateMediaItem(mediaItemUpdate).then(() =>
            dispatch({
                type: 'UPDATE_COLLECTION_ITEM_MEDIA_SUCCESS',
                collectionId,
                itemId,
                mediaItemId,
                mediaItemUpdate
            })
            , err => {
                dispatch({
                    type: 'UPDATE_COLLECTION_ITEM_MEDIA_FAILURE',
                    err
                })
            })
    }

    const sortItemMediaItems = (collectionId, itemId, orderMap) => (dispatch, getState) => {
        const collections = getProjectCollections(getState());
        const collection = collections.find(c => c.id === collectionId);
        const item = angular.copy(collection.items.find(i => i.id === itemId));

        for (const mediaItem of item.mediaItems) {
            mediaItem.sort = orderMap[mediaItem.id];
        }

        //This causes a store update without the sorted items, resulting in a brief flash to the unsorted items

        // dispatch({
        //     type: 'SORT_COLLECTION_ITEM_MEDIA',
        // })

        return ProjectMediaService.updateMediaItems(item.mediaItems).then(() =>
            dispatch({
                type: 'SORT_COLLECTION_ITEM_MEDIA_SUCCESS',
                collectionId,
                itemId,
                orderMap
            })
            , err => {
                dispatch({
                    type: 'SORT_COLLECTION_ITEM_MEDIA_FAILURE',
                    err
                })
            })
    }

    const updateProjectUserRole = (userId, role) => (dispatch, getState) => {
        const projectId = getProjectId(getState());

        const query = `mutation updateProjectUserRole($userId: Int!,  $projectId: Int!){
        result: updateProjectMembership(input: {userId: $userId, projectId: $projectId, role: ${role}}){
            userId
        }
    }`;

        dispatch({
            type: 'UPDATE_PROJECT_USER_ROLE',
            projectId,
            userId,
            role
        });

        return CoreoAPI.mutation(query, null, {
            variables: {
                userId,
                projectId
            }
        }).then(result => {
            dispatch({
                type: 'UPDATE_PROJECT_USER_ROLE_SUCCESS',
                toast: 'User role updated',
                projectUser: result
            });
            return result;
        }, err => {
            let message = err.message;
            if (message === 'This organisation user does not exist') {
                message = 'This user must be a member of the organisation. Please add them to the organisation and try again.'
            }
            dispatch({
                type: 'UPDATE_PROJECT_USER_ROLE_FAILURE',
                toastError: message,
                err
            });
            throw err;
        });
    };

    const addProjectUser = (userId, role) => (dispatch, getState) => {
        const state = getState();
        const projectId = getProjectId(state);
        const organisationId = getProjectOrganisationId(state);

        const query = `mutation inviteProjectUser($organisationId: Int!, $userId: Int!, $projectId: Int!, $role: String!){
        result: addOrganisationUserToProject(input: { organisationId: $organisationId, userId: $userId, projectId: $projectId, role: $role}){
            data,
            marketingConsent,
            role,
            status,
            createdAt,
            userId,
            user{
                id,
                displayName,
                username,
            }
        }
        }`;

        dispatch({
            type: 'ADD_PROJECT_USER',
            projectId,
            organisationId,
            userId,
            role
        });

        return CoreoAPI.mutation(query, null, {
            variables: {
                organisationId,
                projectId,
                userId,
                role
            }
        }).then(projectUser => {
            dispatch({
                type: 'ADD_PROJECT_USER_SUCCESS',
                toast: 'User added to project',
                projectUser
            });
            return projectUser;
        }, err => dispatch({
            type: 'ADD_PROJECT_USER_FAILURE',
            toastError: 'Failed to add user to project',
            err
        }));
    };

    const deleteProjectUser = (userId) => (dispatch, getState) => {
        const state = getState();
        const projectId = getProjectId(state);

        const query = `mutation removeProjectUser($userId: Int!, $projectId: Int!){
        result: downgradeProjectUser(input: { userId: $userId, projectId: $projectId})
        }`;

        dispatch({
            type: 'REMOVE_PROJECT_USER',
            projectId,
            userId
        });

        return CoreoAPI.mutation(query, null, {
            variables: {
                projectId,
                userId
            }
        }).then((result) => {
            dispatch({
                type: 'REMOVE_PROJECT_USER_SUCCESS',
                toast: result ? 'User removed from project' : 'User was made a contributor',
                projectId,
                userId
            });
            return result;
        }, err => dispatch({
            type: 'REMOVE_PROJECT_USER_FAILURE',
            toastError: 'Failed to remove user from project',
            err
        }));
    }

    const createProjectRecordStatus = recordStatus => (dispatch, getState) => {
        dispatch({
            type: 'CREATE_PROJECT_RECORD_STATUS',
        })

        return ProjectService.createProjectVerificationState(recordStatus).then(recordStatus => {
            dispatch({
                type: 'CREATE_PROJECT_RECORD_STATUS_SUCCESS',
                recordStatus,
                toast: 'State added'
            })
        }, err => {
            dispatch({
                type: 'CREATE_PROJECT_RECORD_STATUS_FAILURE',
                err,
                toast: {
                    type: 'error',
                    message: 'Failed to create record status'
                }
            })
        })
    }

    const updateProjectRecordStatus = (recordStatus) => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_PROJECT_RECORD_STATUS',
        })

        return ProjectService.updateProjectVerificationState(recordStatus).then(recordStatus => {
            dispatch({
                type: 'UPDATE_PROJECT_RECORD_STATUS_SUCCESS',
                recordStatus,
                toast: 'State updated'
            })
        }, err => {
            dispatch({
                type: 'UPDATE_PROJECT_RECORD_STATUS_FAILURE',
                err,
                toast: {
                    type: 'error',
                    message: 'Failed to update record status'
                }
            })
        })
    }

    const updateProjectRecordStatusOrder = statuses => (dispatch, getState) => {
        dispatch({
            type: 'UPDATE_PROJECT_RECORD_STATUS_ORDER',
        })

        const projectId = getProjectId(getState());
        const statusesToUpdate = [];

        for (var i = 0; i < statuses.length; i++) {
            const status = statuses[i];
            if (status.sort !== i) {
                status.sort = i;
                statusesToUpdate.push({
                    ...status,
                    projectId,
                    sort: i
                });
            }
        }

        const promises = [];
        for (const status of statusesToUpdate) {
            promises.push(ProjectService.updateProjectVerificationState(status));
        }

        return Promise.all(promises).then(() => {
            dispatch({
                type: 'UPDATE_PROJECT_RECORD_STATUS_ORDER_SUCCESS',
                states: statuses,
                toast: 'Record Status order updated'
            })
        }).catch(err => dispatch({
            type: 'UPDATE_PROJECT_RECORD_STATUS_ORDER_FAILURE',
            toastError: 'Failed to updated Record Status order',
            err
        }))

    }

    const deleteProjectRecordStatus = stateId => (dispatch, getState) => {
        const projectId = getProjectId(getState());
        dispatch({
            type: 'UPDATE_PROJECT_RECORD_STATUS',
        })

        return ProjectService.deleteProjectVerificationState(projectId, stateId).then(recordStatus => {
            dispatch({
                type: 'DELETE_PROJECT_RECORD_STATUS_SUCCESS',
                recordStatus,
                toast: 'State deleted'
            })
        }, err => {
            dispatch({
                type: 'DELETE_PROJECT_RECORD_STATUS_FAILURE',
                err,
                toast: {
                    type: 'error',
                    message: 'Failed to delete record status'
                }
            })
        })
    }

    const deleteProjectMapLayer = id => dispatch => {
        return ProjectMapsService.deleteMapLayer(id).then(() => dispatch({
            type: 'DELETE_PROJECT_MAP_LAYER_SUCCESS',
            id
        }));
    }

    const loadCollections = () => (dispatch, getState) => {
        const projectId = getProjectId(getState());
        dispatch({
            type: 'LOAD_COLLECTIONS'
        });

        return ProjectService.getCollections(projectId).then((collections) => dispatch({
            type: 'LOAD_COLLECTIONS_SUCCESS',
            collections
        }), err => dispatch({
            type: 'LOAD_COLLECTIONS_FAILURE',
            err
        }));
    }

    return {
        loadProject,
        loadProjectStats,
        deleteProject,
        createForm,
        deleteForm,
        updateForm,
        updateForms,
        createCollection,
        createCollectionItem,
        updateCollection,
        updateCollectionSettings,
        updateCollectionItem,
        updateCollectionItems,
        importCollectionItems,
        deleteCollection,
        deleteCollectionItem,
        createAttribute,
        updateAttribute,
        deleteAttribute,
        loadCollectionItem,
        loadCollectionItems,
        updateProject,
        createCredential,
        updateCredential,
        reorderProjectPages,
        addProjectPage,
        deleteProjectPage,
        deleteItemMediaItem,
        createItemMediaItem,
        updateItemMediaItem,
        sortItemMediaItems,
        addProjectUser,
        deleteProjectUser,
        updateProjectUserRole,
        createProjectRecordStatus,
        updateProjectRecordStatus,
        deleteProjectRecordStatus,
        updateProjectRecordStatusOrder,
        loadCollection,
        loadCollections,
        deleteProjectMapLayer
    };
};

projectActions.$inject = ['ProjectService', 'ProjectPagesService', 'ProjectMediaService', 'ProjectMapsService', '$rootScope', 'CoreoAPI', 'ProjectDependencyErrorService'];
export default projectActions;