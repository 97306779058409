<div class="h-full w-full">
    <div class="h-100 text-text-primary">
        <div class="h-full w-full flex items-center justify-center">
            @switch (state()) {
            @case ('warning') {
            <div class="max-w-[561px] flex flex-col items-center">
                <h2 class="text-2xl font-semibold text-center mb-10">Regenerate recovery codes</h2>
                <p class="text-sm text-center mb-5">If you lose your authenticator app you can still log in using your
                    single use backup codes. You will only see these once so please make a copy before continuing.</p>
                <p-button label="Generate codes" size="large" [outlined]="true" (click)="generateCodes()"></p-button>
                @if (error()) {
                <p class="text-danger text-center mt-5">There was an error, please try again.</p>
                }
            </div>
            }
            @case ('recoveryCodes') {
            <div class="px-10 flex flex-col items-center">
                <h2 class="text-2xl font-semibold text-center mb-16">Single Use Recovery Codes</h2>
                <div class="flex items-center gap-10">
                    <div class="w-1/2 grid grid-cols-2 gap-5">
                        @for (code of recoveryCodes(); track code) {
                        <p class="text-xs font-mono font-semibold">{{ code }}</p>
                        }
                    </div>
                    <div class="w-1/2">
                        <div class="flex flex-col">
                            <p class="text-xs mb-4">Copy the codes, and keep them safe. If you do not have access to
                                either these codes or your authenticator you will be locked out of your account.</p>
                            <p class="text-xs mb-5">You will not see these again. If you lose these codes, but still
                                have access to your account you can regenerate new ones in you account settings.</p>

                            <div class="flex items-center gap-4">
                                <p-button label="Copy codes" [outlined]="true" (click)="copyRecoveryCodes()"></p-button>
                                @if (recoveryCodesCopied()) {
                                <p class="text-[#57B771] text-xs flex items-center"><svg-icon
                                        src="/assets/icons/tick.svg" class="inline-block mr-1"></svg-icon>Codes copied
                                </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            }
        </div>
    </div>
    <div class="h-16 flex items-end justify-end">
        @if (state() === 'warning') {
        <p-button size="large" label="Cancel" type="button" [outlined]="true" (click)="dialogRef.close()"></p-button>
        }
        @else {
        <p-button size="large" label="Finish" type="button" (click)="dialogRef.close(true)"></p-button>
        }
    </div>
</div>