import { CommonModule } from '@angular/common';
import { Component, computed, effect, ElementRef, EventEmitter, input, Output, signal, viewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
    selector: 'app-two-fa-input',
    templateUrl: './2fa-input.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        SvgIconComponent
    ],
    styles: [`:host { @apply block;}`]
})

export class TwoFAInputComponent {

    label = input('Enter code');
    verifying = input(false);

    @Output() codeValid: EventEmitter<string> = new EventEmitter();

    twoFAInput = viewChild<ElementRef>('twoFAInput');

    twoFAValue = signal<number | null>(null);
    twoFACode = computed(() => {
        const value = this.twoFAValue();
        if (value) {
            return value.toString();
        } else {
            return '';
        }
        
    });
    error = signal(false);

    constructor() {
        effect(() => {
            this.twoFAInput() && this.focusInput();
        });
    }

    focusInput() {
        this.twoFAInput().nativeElement.focus();
    }

    isActive(index: number) {
        const code = this.twoFACode();
        const focused = document.activeElement === this.twoFAInput().nativeElement;
        return focused && (
            (!code.length && index === 0) ||
            (code[index - 1] && !code[index] && !code[index + 1]) ||
            (code.length === 6 && index === 5)
        );
    }

    codeChanged() {
        const code = this.twoFACode();

        const lengthValid = code.length === 6;
        const regExValid = new RegExp('[0-9]').test(code);

        if (lengthValid && !regExValid) {
            this.error.set(true);
            this.twoFAValue.set(null);
            return;
        }
        if (code.length > 0) {
            this.error.set(false);
        }
        if (lengthValid && regExValid) {
            this.codeValid.emit(code);
        }
    }
}