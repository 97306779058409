<p-toast position="bottom-center"></p-toast>
<p-confirmDialog></p-confirmDialog>
<app-upload-profile-image
    [isOpen]="imageDialogOpen()"
    (imageChange)="onImageChange($event)" (cancel)="onImageCancel()"/>

<div class="flex flex-col h-auto max-h-full max-w-[862px] items-center justify-start gap-5">
    @if(profile(); as profile){
    <form [formGroup]="profileForm" class="w-full" (ngSubmit)="onSubmit()">
        <p-panel class="flex flex-col items-start" styleClass="w-full">
            <ng-template pTemplate="header">
                <div class="flex items-center gap-3">
                    <i class="fas fa-user"></i>
                    <span class="p-panel-title">My Profile</span>
                </div>
                <div>
                    <p-button size="large" label="Save changes" type="submit" [disabled]="!profileForm.touched || !profileForm.valid"/>
                </div>
            </ng-template>
            <div class="flex items-center justify-center gap-12 p-8">
                <div class="flex flex-col items-center justify-between gap-5 max-w-[275px]">
                    <p-avatar styleClass="w-[142px] h-[142px] items-center bg-[#DFEEFF] rounded-full">
                        <img [src]="imageUrl || './assets/images/user.svg'" class="w-[142px] h-[142px] rounded-full object-cover" />
                    </p-avatar>
                    <p-button type="button" (click)="imageDialogOpen.set(true)" [outlined]="true" label="Add a profile picture" />
                </div>

                <div class="flex flex-col gap-5">
                    <h2 class="text-sm font-semibold">{{profile.email}}</h2>
                    <div class="flex w-full flex-col gap-2">
                        <label for="name" class="text-sm text-grey-120 font-normal">Name</label>
                        <input type="text" pInputText formControlName="displayName" class="w-full h-[44px]">
                    </div>

                    <div class="flex w-full flex-col gap-2">
                        <label for="username" class="text-sm text-grey-120 font-normal">Username</label>
                        <input type="text" pInputText formControlName="username" class="w-full h-[44px]" />
                    </div>
                </div>
            </div>
        </p-panel>
    </form>
    <div class="grid grid-cols-2 grid-rows-1 w-full gap-5">
        <p-panel class="flex flex-col items-start" styleClass="w-full">
            <ng-template pTemplate="header">
                <div class="flex items-center gap-3">
                    <i class="fas fa-lock"></i>
                    <span class="p-panel-title">Account Security</span>
                </div>
            </ng-template>
            <div class="p-8">
                <div class="flex flex-col items-start justify-between h-32">
                    @if(profile.mfaEnabled){
                    <p class="text-sm text-[#444444]">You have Two-Factor Authentication enabled</p>
                    <p-button label="Disable Two-Factor Authentication" [outlined]="true" size="large" (click)="disable2FA($event)" class="inline-block"/>
                    <p class="text-xs">If you've lost your backup codes you can <button class="inline text-primary font-semibold underline" (click)="regenerateRecoveryCodes($event)">regenerate new codes here</button></p> 
                    } @else {
                    <p class="text-sm text-[#444444]">Add a second log in step to keep your account safe, using an authenticator app</p>
                    <p-button label="Enable Two-Factor Authentication" [outlined]="true" size="large" (click)="enable2FA()" class="inline-block"/>
                    }
                </div>
            </div>
        </p-panel>
        <p-panel class="flex flex-col items-start" styleClass="w-full">
            <ng-template pTemplate="header">
                <div class="flex items-center gap-3">
                    <i class="fas fa-building"></i>
                    <span class="p-panel-title">My Organisations</span>
                </div>
            </ng-template>
            <div class="px-4">
                <div class="flex flex-col items-start justify-between h-[192px] overflow-auto px-4">
                    @for (organisation of organisations; track $index) {
                    <div class="flex items-center justify-between w-full border-b border-[#D3DBE3] max-h-[54px] py-3.5">
                        <span class="text-sm text-black">{{ organisation.name }}</span>
                        <span class="uppercase text-[10px] font-semibold border rounded-md py-1 px-2 {{organisation.role === 'admin' || organisation.role === 'owner' ? 'bg-[#FFF3E9] text-[#FC7A1E] border-[#FC7A1E]' : 'bg-[#E8EBF0] text-[#15CB64] border-[#15CB64]'}}">
                            {{ organisation.role }}
                        </span>
                    </div>
                    }
                </div>
            </div>
        </p-panel>
    </div>
    }
</div>