import { z } from 'zod';

export const ProjectCloneUserSchema = z.object({
    user: z.object({
        id: z.number(),
        username: z.string(),
        displayName: z.string()
    })
});

export const ProjectCloneProjectSchema = z.object({
    id: z.number(),
    name: z.string(),
});

export const ProjectCloneOrganisationSchema = z.object({
    id: z.number(),
    name: z.string()
});

export const ProjectSettingsSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    description: z.string().nullable(),
    accessMode: z.string(),
    imageUrl: z.string().nullable(),
    fromAddress: z.string().nullable(),
    accountVerificationUrl: z.string().nullable(),
    passwordResetUrl: z.string().nullable(),
    androidAppUrl: z.string().nullable(),
    iosAppUrl: z.string().nullable(),
    bespoke: z.boolean(),
    verification: z.boolean(),
    visible: z.boolean(),
    shortName: z.string().nullable(),
    welcomePageId: z.number().nullable(),
    allowContributors: z.boolean(),
    hideUsernames: z.boolean(),
    locked: z.boolean(),
    usersLimit: z.number().nullable(),
    geometry: z.any().nullable()
}).partial();

export const OrganisationSlugSchema = z.object({
    slug: z.string()
});

export const ProjectTableItemSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    createdAt: z.string(),
    visible: z.boolean(),
});

export const ProjectTableItemsSchema = z.object({
    projects: ProjectTableItemSchema.array()
});

export const ProjectCreateOrganisationSchema = z.object({
    id: z.number(),
    name: z.string()
});

export const ProjectAdminSchema = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string(),
    androidAppUrl: z.string().nullable(),
    organisation: OrganisationSlugSchema
});



export const ProjectCollectionSchema = z.object({
    id: z.number(),
    type: z.string(),
    name: z.string(),
    itemCount: z.number(),
    mapLayer: z.boolean(),
    mapSort: z.number(),
    mapVisible: z.boolean(),
    sortAttributeId: z.number().nullable(),
    sortMode: z.string(),
    sortReverse: z.boolean(),
    status: z.string().nullable(),
    projectId: z.number().optional(),
    geometric: z.boolean(),
    style: z.any().nullable()
});

export type ProjectSettings = z.infer<typeof ProjectSettingsSchema>;
export type ProjectCloneUser = z.infer<typeof ProjectCloneUserSchema>;
export type ProjectCloneProject = z.infer<typeof ProjectCloneProjectSchema>;
export type ProjectCloneOrganisation = z.infer<typeof ProjectCloneOrganisationSchema>;

export type ProjectTableItem = z.infer<typeof ProjectTableItemSchema>;
export type ProjectCreateOrganisation = z.infer<typeof ProjectCreateOrganisationSchema>;

export type ProjectCollection = z.infer<typeof ProjectCollectionSchema>;