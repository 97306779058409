import { NgOptimizedImage } from "@angular/common";
import { ChangeDetectionStrategy, Component, HostListener, inject, Input } from "@angular/core";
import { DialogService } from "primeng/dynamicdialog";
import { ImageModalComponent, ImageModalProps } from "../image-modal/image-modal.component";

@Component({
    selector: 'app-image-modal-viewer',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `@for(i of images; track i; let index = $index){
        <img [width]="width" [height]="height" [ngSrc]="i" (click)="open($event, index)"/>
    }`,
    imports: [
        NgOptimizedImage
    ],
    providers: [
        DialogService
    ],
    styles: [`
    :host{
        @apply flex gap-2;
    }
    img{
        border-radius: 4px;
        border: 1px solid #fff;
        width: 40px;
        height: 40px;
    }
    `]
})
export class ImageModalViewerComponent {

    dialogService = inject(DialogService);

    @Input() images: string[];
    @Input() path: string;
    @Input() label: string;
    @Input() width: number = 40;
    @Input() height: number = 40;

    open(event: MouseEvent, index: number) {
        event.preventDefault();
        event.stopPropagation();
        const images = this.images.map(i => {
            const split = i.split('/');
            const filename = split[split.length - 1];
            const name = `${this.path}-${filename}`;
            return { url: i, name }
        });
        const imageProps: ImageModalProps = {
            images,
            index
        };

        this.dialogService.open(ImageModalComponent, {
            data: imageProps,
            header: this.label,
            width: '75vw',
            height: '90vh',
            modal: true,
            breakpoints: {
                '960px': '75vw',
                '640px': '90vw'
            },
            contentStyle: { 'padding': '0px' }
        });
    }



}