import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, Output, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ApiService, GraphqlError } from 'src/app/core';
import { AuthService } from '../auth.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { allowedSymbols, PasswordFeedbackComponent } from '../password-feedback/password-feedback.component';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { catchError, of, take } from 'rxjs';
import { SvgIconComponent } from 'angular-svg-icon';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styleUrls: ['./signup.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        PasswordFeedbackComponent,
        CheckboxModule,
        SvgIconComponent,
        ToastModule
    ],
    providers: [
        MessageService
    ]
})

export class SignupComponent {

    fb = inject(FormBuilder);
    api = inject(ApiService);
    auth = inject(AuthService);
    message = inject(MessageService);

    @Output() success: EventEmitter<{ email: string; password: string; token: string }> = new EventEmitter();

    signupForm: FormGroup = this.fb.group({
        email: ['', [Validators.email, Validators.required]],
        fullName: ['', Validators.required],
        password: ['', Validators.required],
        organisation: ['', Validators.required],
        terms: [null, Validators.required],
        marketingConsent: [null]
    });

    email = toSignal(this.signupForm.get('email').valueChanges, { initialValue: '' });
    password = toSignal(this.signupForm.get('password').valueChanges, { initialValue: '' });
    fullName = toSignal(this.signupForm.get('fullName').valueChanges, { initialValue: '' });
    organisation = toSignal(this.signupForm.get('organisation').valueChanges, { initialValue: '' });
    terms = toSignal(this.signupForm.get('organisation').valueChanges, { initialValue: null });
    marketingConsent = toSignal(this.signupForm.get('organisation').valueChanges, { initialValue: null });
    passwordType = signal<'password' | 'text'>('password');
    submitting = signal(false);
    emailError = signal<string | null>(null);
    signupError = signal<string | null>(null);
    signupComplete = signal(false);

    passwordValid = computed<boolean>(() => {
        const password = this.password();
        const length = password.length >= 8;
        const lowercase = new RegExp('[a-z]').test(password);
        const uppercase = new RegExp('[A-Z]').test(password);
        const symbol = new RegExp(`[${allowedSymbols}]`).test(password);
        const number = new RegExp('[0-9]').test(password);
        return (
            !!password &&
            length &&
            lowercase &&
            uppercase &&
            symbol &&
            number
        );
    });

    togglePasswordType() {
        this.passwordType.update(type => type === 'password' ? 'text' : 'password');
    }

    submit() {
        this.submitting.set(true);
        const email = this.email();
        const password = this.password();
        const displayName = this.fullName();
        const marketingConsent = this.marketingConsent() || false;
        const trialOrganisationName = this.organisation();

        this.auth.signup(email, password, displayName, marketingConsent, trialOrganisationName).pipe(
            take(1),
            catchError((err: GraphqlError) => {
                console.error(err);
                if (err.message === 'Email already taken') {
                    this.emailError.set(err.message);
                } else if (err.message === 'Email delivery failed') {
                    this.message.add({ severity: 'error', summary: 'Error', detail: 'Email delivery failed' });
                } else {
                    this.signupError.set(err.message);
                }
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                const token = res.token;
                this.signupComplete.set(true);
                this.success.emit({ email, password, token });
            }
            this.submitting.set(false);
        });
    }
}