import { Component, OnInit } from '@angular/core';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/core';
import { catchError, of, take } from 'rxjs';
import { CollectionAttribute } from '../attribute-table/attribute-table.component';
import { InputSwitchModule } from 'primeng/inputswitch';

const attributeTypes = [
    'text',
    'float',
] as const;
type AttributeType = typeof attributeTypes[number];

@Component({
    selector: 'app-add-edit-attribute-modal',
    templateUrl: 'add-edit-attribute-modal.component.html',
    imports: [
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        SelectButtonModule,
        InputSwitchModule       
    ],
    standalone: true
})

export class AddEditAttributeModalComponent implements OnInit {

    private projectId: number;
    private collectionId: number;
    private attribute: CollectionAttribute;

    public isEdit: boolean = false;
    public loading: boolean = false;
    
    public attributeForm = new FormGroup({
        label: new FormControl('', Validators.required),
        type: new FormControl('text'),
        visible: new FormControl(true)
    });

    public typeOptions: {label: string; value: AttributeType}[] = [
        { label: 'Text', value: 'text' },
        { label: 'Numeric', value: 'float' },
    ];

    constructor(
        private dialogRef: DynamicDialogRef,
        private dialogConfig: DynamicDialogConfig,
        private apiService: ApiService
    ) { }

    ngOnInit() { 
        this.projectId = this.dialogConfig.data['projectId'];
        this.collectionId = this.dialogConfig.data['collectionId'];
        this.attribute = this.dialogConfig.data['attribute'];

        if (this.attribute) {
            this.isEdit = true;
            this.attributeForm.get('label').setValue(this.attribute.label);
            this.attributeForm.get('type').setValue(this.attribute.type);
            this.attributeForm.get('visible').setValue(this.attribute.visible);
        }
    }

    get label() {
        return this.attributeForm.get('label');
    }

    public onSubmit() {
        this.loading = true;

        const newAttributeQuery = `mutation addNewCollectionAttribute($input: CollectionAttributeCreateInput!){
            createCollectionAttribute(input: $input){
                id
            }
        }`;

        const updateAttributeQuery = `mutation updateCollectionAttribute($input: collectionAttributeUpdateInput!){
            updateCollectionAttribute(input: $input){
                id
            }
        }`;

        const query = this.isEdit ? updateAttributeQuery : newAttributeQuery;

        const { value } = this.attributeForm;
        

        let input = {
            label: value.label,
            type: value.type,
            visible: value.visible,
            path: value.label.toLowerCase().trim().replace(/\s+/g, '_')
        };

        if (this.isEdit) {
            input['id'] = this.attribute.id;
        } else {
            input['projectId'] = this.projectId;
            input['parentCollectionId'] = this.collectionId;
        }

        this.apiService.graphql(query, { input }).pipe(
            take(1),
            catchError((e) => {
                console.error(e);
                // show error toast/message?
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                // success show toast?
                // close modal and reload table
                this.dialogRef.close(true)
            }
            this.loading = false;
        });
    }

    public delete() {
        // should we have a confirm dialog here?
        const query = `mutation deleteCollectionAttribute{
            deleteAttribute(input: { id: ${this.attribute.id} })
        }`;

        this.apiService.graphql<{ deleteAttribute: number }>(query).pipe(
            take(1),
            catchError((e) => {
                console.warn(e);
                // toast
                return of(null);
            })
        ).subscribe(res => {
            if (!!res) {
                this.dialogRef.close(true);
            }
        });
    }
}