import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output, signal, viewChild } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { catchError, of, take } from "rxjs";
import { ApiService, GraphqlError } from "src/app/core";
import { AuthService, LoginResult } from "../auth.service";
import { CommonModule } from "@angular/common";
import { TwoFAInputComponent } from "../2fa-input/2fa-input.component";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    styleUrls: ['./login.component.scss'],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        InputTextModule,
        ButtonModule,
        TwoFAInputComponent
    ]
})
export class LoginComponent {

    fb = inject(FormBuilder);
    api = inject(ApiService);
    auth = inject(AuthService);

    twoFAInputComponent = viewChild<TwoFAInputComponent>('twoFAInput');

    @Output() success: EventEmitter<LoginResult> = new EventEmitter();

    loginForm: FormGroup = this.fb.group({
        email: ['', [Validators.email, Validators.required]],
        password: ['', Validators.required]
    });

    recoverForm: FormGroup = this.fb.group({
        code: ['', [Validators.required]]
    });

    email = toSignal(this.loginForm.get('email').valueChanges, { initialValue: '' });
    password = toSignal(this.loginForm.get('password').valueChanges, { initialValue: '' });
    formStatus = toSignal(this.loginForm.statusChanges, { initialValue: 'VALID' });

    state = signal<'login' | '2fa' | '2faRecover'>('login');

    submitting = signal<boolean>(false);
    loginError = signal<string>('');
    passwordType = signal<'password' | 'text'>('password');

    pre2FAToken: string;

    verifying2FA = signal(false);

    recoverError = signal(false);

    login() {
        this.submitting.set(true);
        this.auth.login(this.email(), this.password()).pipe(
            catchError((err: GraphqlError) => {
                console.error(err);
                this.loginError.set(err.message);
                this.submitting.set(false);
                return [];
            })
        ).subscribe(data => {
            if (data.pre2FAToken) {
                this.submitting.set(false);
                this.state.set('2fa');
                this.pre2FAToken = data.pre2FAToken;
                return;
            }
            this.success.emit(data);
        });
    }

    togglePasswordType() {
        this.passwordType.update(type => type === 'password' ? 'text' : 'password');
    }

    mfa(code: string) {
        this.verifying2FA.set(true);
        this.auth.verify2FA(this.pre2FAToken, code).pipe(
            take(1),
            catchError((err) => {
                console.error(err);
                this.twoFAInputComponent().error.set(true);
                this.verifying2FA.set(false);
                return of(null);
            })
        ).subscribe(data => {
            if (!!data) {
                this.success.emit(data);
            }
        });
    }

    recover() {
        this.recoverError.set(false);
        this.auth.recover2FA(this.pre2FAToken, this.recoverForm.get('code').value).pipe(
            take(1),
            catchError((err) => {
                console.error(err);
                this.recoverError.set(true);
                return of(null);
            })
        ).subscribe(data => {
            if (!!data) {
                this.success.emit(data);
            }
        });
    }
}