import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

export const allowedSymbols = "!\"#\$%&'\(\)\*\+,-\./:;<=>\?@\[\\\\\\]\^_`\{|\}~";

@Component({
    selector: 'app-password-feedback',
    templateUrl: './password-feedback.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule
    ]
})

export class PasswordFeedbackComponent {
    
    password = input<string>();
   
    length = computed<boolean>(() => {
        const password = this.password();
        return password && password.length >= 8;
    });
    lowercase = computed<boolean>(() => {
        const password = this.password();
        const regex = '[a-z]';
        return password && new RegExp(regex).test(password);
    });
    uppercase = computed<boolean>(() => {
        const password = this.password();
        const regex = '[A-Z]';
        return password && new RegExp(regex).test(password);
    });
    symbol = computed<boolean>(() => {
        const password = this.password();
        const regex = `[${allowedSymbols}]`;
        return password && new RegExp(regex).test(password);
    });
    number = computed<boolean>(() => {
        const password = this.password();
        const regex = '[0-9]';
        return password && new RegExp(regex).test(password);
    });

    passwordConfig = computed<{ message: string; key: string; valid: boolean }[]>(() => {
        const length = this.length();
        const lowercase = this.lowercase();
        const uppercase = this.uppercase();
        const symbol = this.symbol();
        const number = this.number();

        return [
            { message: '8 or more characters', key: 'length', valid: length },
            { message: '1 or more lowercase letter', key: 'lowercase', valid: lowercase },
            { message: '1 or more uppercase letter', key: 'uppercase', valid: uppercase },
            { message: '1 or more number', key: 'number', valid: number },
            { message: '1 or more special character', key: 'symbol', valid: symbol },
        ];
    });

    valid = computed<boolean>(() => {
        const length = this.length();
        const lowercase = this.lowercase();
        const uppercase = this.uppercase();
        const symbol = this.symbol();
        const number = this.number();

        return (
            length &&
            lowercase &&
            uppercase &&
            symbol &&
            number
        );
    });
}