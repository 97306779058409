import { Component, OnInit, Input, Output, EventEmitter, inject, signal, computed, ViewContainerRef, viewChild, effect, ElementRef } from '@angular/core';
import { ReactiveFormsModule, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgIf } from '@angular/common';
import { ToastModule } from "primeng/toast";
import { PanelModule } from 'primeng/panel';
import { MessageService } from "primeng/api";
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { ProjectCloneComponent } from './project-clone.component';
import { ProjectRemoveComponent } from './project-remove.component';
import { ApiService } from "../../../core/services/api.service";
import { ProjectSettings, ProjectSettingsSchema } from '../../../core/models/project.model';
import { Feature, FeatureCollection, MultiPolygon, Polygon } from 'geojson';
import bbox from '@turf/bbox';
import MapboxGIS from '@natural-apptitude/coreo-mapbox';
import { MapboxGISBaseMode } from '@natural-apptitude/coreo-mapbox/dist/types';
import { MAP_MENU_LAYERS_CHANGED_EVENT, MAP_MENU_STYLE_CHANGED_EVENT, MapsService } from 'src/app/core/services/maps.service';
import mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';

const adminSettingKeys = ['allowContributors', 'hideUsernames', 'locked', 'slug', 'usersLimit'] as const;
const projectSettingKeys = ['name', 'description', 'verification', 'visible', 'accessMode', 'imageUrl', 'facebookHandle', 'twitterHandle', 'instagramHandle', 'id', 'shortName', 'access', 'fromAddress', 'accountVerificationUrl', 'passwordResetUrl', 'androidAppUrl', 'iosAppUrl', 'welcomePageId'] as const;

@Component({
    selector: 'app-project-settings',
    templateUrl: './project-settings.component.html',
    imports: [
        ReactiveFormsModule,
        NgIf,
        ToastModule,
        PanelModule,
        ButtonModule,
        InputTextModule,
        CheckboxModule,
        ProjectCloneComponent,
        ProjectRemoveComponent
    ],
    standalone: true,
    styles: [
        `:host{ @apply block p-8 space-y-4; }`
    ],
    providers: [MessageService]
})
export class ProjectSettingsComponent implements OnInit {

    apiService = inject(ApiService);
    messageService = inject(MessageService);
    mapsService = inject(MapsService);
    viewContainerRef = inject(ViewContainerRef);

    mapContainer = viewChild<ElementRef>('mapContainer');

    @Input() project: any;
    @Input() role: string;
    @Input() orgRole: string;
    @Input() isAdmin: boolean;
    @Input() organisationId: number;
    @Input() parentProjectId: number;

    @Input() bounds: any;
    @Input() mapbox: any;

    @Output() removed: EventEmitter<void> = new EventEmitter();
    @Output() update: EventEmitter<any> = new EventEmitter();

    currentProject: ProjectSettings;
    ro: ResizeObserver;

    map: mapboxgl.Map;
    mapboxGIS: MapboxGIS;
    boundsDirty: boolean = false;
    canClone: boolean = false;

    isVisibleCloneDialog: boolean = false;
    isVisibleRemoveDialog: boolean = false;

    projectSettingsForm = new FormGroup({
        name: new FormControl<string>('', [Validators.required]),
        description: new FormControl<string>(''),
        slug: new FormControl<string>('', [Validators.required]),
        usersLimit: new FormControl<number>(null),
        locked: new FormControl<boolean>(false, [Validators.required]),
        allowContributors: new FormControl<boolean>(false, [Validators.required]),
        hideUsernames: new FormControl<boolean>(false, [Validators.required]),
        fromAddress: new FormControl<string>(null),
        accountVerificationUrl: new FormControl<string>(''),
        passwordResetUrl: new FormControl<string>(''),
        androidAppUrl: new FormControl<string>(null),
        iosAppUrl: new FormControl<string>(null)
    });

    isSatellite = signal<boolean>(true);
    isStyleLoaded = signal<boolean>(false);
    currentStyle = computed<string>(() => this.isSatellite() ? 'mapbox://styles/mapbox/satellite-v9' : 'mapbox://styles/mapbox/streets-v12');

    ngOnInit() {
        try {
            this.currentProject = ProjectSettingsSchema.parse(this.project);
            this.initForm();
            this.initMap();
            this.canClone = this.parentProjectId === null && (this.isAdmin || (!this.currentProject.locked && (this.orgRole === 'admin' || this.orgRole === 'owner')));
        } catch (error) {
            console.error(error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error has occurred' });
            this.removed.emit();
        }
    }

    ngOnDestroy() {
        this.ro?.disconnect();
    }

    initForm() {
        [...adminSettingKeys, ...projectSettingKeys].forEach(key => {
            this.projectSettingsForm.get(key)?.setValue(this.currentProject[key]);
        });
    }

    async initMap() {
        const MAP_DEFAULT_BOUNDS = [
            2.0153808593734936, 56.6877748258257,
            -7.0153808593762506, 47.45206245445874
        ];

        const mapOptions: mapboxgl.MapboxOptions = {
            container: this.mapContainer().nativeElement,
            style: this.currentStyle(),
            renderWorldCopies: false,
            bounds: MAP_DEFAULT_BOUNDS as any,
            fitBoundsOptions: null,
            accessToken: environment.mapboxApiKey
        };

        if (this.currentProject.geometry && this.bounds) {
            mapOptions.bounds = this.bounds;
            mapOptions.fitBoundsOptions = { padding: 60 };
        }

        const { default: MapboxGIS } = await import('@natural-apptitude/coreo-mapbox');
        let feature = null;
        let mode: MapboxGISBaseMode = 'create';
        if (this.currentProject.geometry) {
            feature = {
                type: 'Feature',
                geometry: this.currentProject.geometry,
                properties: {}
            };
            mode = 'update';
        }
        this.mapboxGIS = new MapboxGIS({
            geometryType: 'MultiPolygon',
            feature,
            modes: ['create', 'update', 'delete'],
            mode,
            allowedGeometryTypes: ['MultiPolygon'],
            typeControl: false,
            toolbarControl: false,
            interface: 'desktop',
            logging: false
        });

        this.mapboxGIS.on('geometry', (e) => {
            this.currentProject.geometry = e?.geometry ?? null;
            this.boundsDirty = true;
        });


        const handleStyleLoad = () => {
            if (this.map.hasControl(this.mapboxGIS)) {
                this.map.removeControl(this.mapboxGIS);
            }
            this.map.addControl(this.mapboxGIS);
            this.isStyleLoaded.set(true);
        };

        this.map = new mapboxgl.Map(mapOptions);

        this.map.once('load', () => {
            handleStyleLoad();
            this.map.on(MAP_MENU_STYLE_CHANGED_EVENT, handleStyleLoad);
        });

        this.map.on(MAP_MENU_LAYERS_CHANGED_EVENT, () => {
            console.log('Loaded!');
            this.mapboxGIS.moveToTop();
        });

        this.map.addControl(new mapboxgl.FullscreenControl());
        this.mapsService.attachMapMenuControl(this.map, this.viewContainerRef, {
            position: 'top-left',
            showProjectBoundsToggle: false
        });

        this.ro = new ResizeObserver(() => this.map.resize());
        this.ro.observe(this.mapContainer().nativeElement);
    }

    switchBase() {
        this.isStyleLoaded.set(false);
        this.isSatellite.set(!this.isSatellite());
        this.map.removeControl(this.mapboxGIS);
        this.map.setStyle(this.currentStyle());
    }

    updateProjectBounds = (feature: Feature<MultiPolygon>) => {
        this.currentProject.geometry = feature.geometry;
        this.bounds = bbox(this.currentProject.geometry);
        this.map.fitBounds(this.bounds, {
            padding: 60
        });
        this.mapboxGIS.setFeature(feature);
        this.boundsDirty = true;
    };

    onSubmitUpdate() {
        const keys = this.isAdmin ? [...adminSettingKeys, ...projectSettingKeys] : [...projectSettingKeys];
        const updatedValues = {};
        keys.forEach(key => {
            const formValue = this.projectSettingsForm.value[key];
            const currentProjectValue = this.currentProject[key];

            if (formValue != null) {
                updatedValues[key] = formValue;
            } else if (currentProjectValue != null) {
                updatedValues[key] = currentProjectValue;
            }
        });

        this.updateProject(updatedValues).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Project updated' });
            this.projectSettingsForm.markAsPristine();
            this.update.emit(updatedValues);
        },
            (error) => {
                console.error(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Project failed to update' });
            });
    }

    startBoundsUpload() {
        document.getElementById('project-settings-bounds-upload').click();
    }

    handleBoundsFileChange = (e) => {
        const file: File = e.target.files[0];
        e.target.value = null;

        if (!file) {
            return;
        }

        switch (file.type) {
            case 'zip':
            case 'application/octet-stream':
            case 'application/zip':
            case 'application/x-zip':
            case 'application/x-zip-compressed': {
                return this.uploadShapefileBounds(file);
            }
            default: {
                return this.uploadGeoBounds(file);
            }
        }
    }

    uploadGeoBounds(file) {
        const reader = new FileReader();
        reader.onload = () => {
            const geojson = JSON.parse(reader.result as string);
            if (geojson.type !== 'FeatureCollection' || geojson.features.length === 0 || geojson.features.length > 1 || geojson.features[0].geometry.type !== 'MultiPolygon') {
                this.messageService.add({ severity: 'error', summary: 'Invalid GeoJSON', detail: 'GeoJSON be a FeatureCollection containing a single MultiPolygon feature' });
                return;
            }
            this.updateProjectBounds(geojson.features[0] as Feature<MultiPolygon>);
        };
        reader.readAsText(file);
    }

    uploadShapefileBounds(file) {
        const formData = new FormData();
        formData.append('file', file);
        this.apiService.post<FeatureCollection>('/gis/shapefile', formData).subscribe(t => {
            if (!t.features || t.features.length === 0 || t.features.length > 1) {
                this.messageService.add({ severity: 'error', summary: 'Invalid Shapefile ', detail: 'Shapefile must contain exactly one Polygon or MultiPolygon feature' });
                return;
            }
            const feature = t.features[0];
            if (!['Polygon', 'MultiPolygon'].includes(feature.geometry.type)) {
                this.messageService.add({ severity: 'error', summary: 'Invalid Shapefile', detail: 'Shapefile must contain exactly one Polygon or MultiPolygon feature' });
                return;
            }
            if (feature.geometry.type === 'Polygon') {
                feature.geometry = {
                    type: 'MultiPolygon',
                    coordinates: [feature.geometry.coordinates]
                };
            }

            this.updateProjectBounds(feature as Feature<MultiPolygon>);
        });
    }

    saveProjectBounds() {
        const { id, geometry } = this.currentProject;
        this.updateProject({ id, geometry }).subscribe(() => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Project updated' });
            this.boundsDirty = false;
            this.update.emit({ geometry });
        },
            (error) => {
                console.error(error);
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Project failed to update' });
            });
    }



    updateProject(project) {
        const query = `mutation AAUpdateProject($input: ProjectUpdateInput!) {
      updateProject(input: $input) { id }
    }`
        return this.apiService.graphql(query, { input: project });
    }

    onVisibleCloneChange(event: { isVisibleCloneDialog: boolean }) {
        this.isVisibleCloneDialog = event.isVisibleCloneDialog;
    }

    onVisibleRemoveChange(event: { isVisibleRemoveDialog: boolean; isRemoved: boolean }) {
        this.isVisibleRemoveDialog = event.isVisibleRemoveDialog;
        if (event.isRemoved) this.removed.emit();
    }
}