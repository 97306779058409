import { Injectable, Injector, computed, effect, inject, signal } from '@angular/core';
import { Subject, Subscription, catchError, interval, map, of, switchMap } from 'rxjs';
import { ApiService } from './api.service';
import { DownloadService } from './download.service';
import { UserService } from './user.service';

export type CoreoJobType =
    | 'exportRecords'
    | 'exportRecordsMedia'
    | 'exportRecordsLegacy'
    | 'exportRecordLogsCSV'
    | 'exportCollectionCSV'
    | 'exportProjectUsers'
    | 'exportOrganisationUsers'
    | 'cloneProject'
    | 'importRecords'
    | 'importCSVCollection'
    | 'importShapefileCollection'
    | 'importGeoJSONCollection'
    | 'importBoundaries';

type CoreoJobStatus = 'complete' | 'pending' | 'failed' | 'processing';
export type CoreoJob = {
    id: number;
    uuid: string;
    status: CoreoJobStatus;
    url: string;
    type: CoreoJobType;
    message: string;
    options: any;
    createdAt: string;
    updatedAt: string;
}

export const jobFieldsFragment = `fragment jobFields on Job {
    id
    uuid
    status
    url
    type
    options
    message
    createdAt
    updatedAt
}`;

interface JobsLoadProjectResponse {
    project: {
        slug: string;
        organisation: {
            slug: string;
        }
    }
}

const jobsQuery = `query CoreoAAGetJobs{
    viewer{
        jobs{
            ...jobFields
        }
    }
}
${jobFieldsFragment}`;


@Injectable({
    'providedIn': 'root',
})
export class JobsService {

    apiService = inject(ApiService);
    userService = inject(UserService);
    injector = inject(Injector);
    downloadService = inject(DownloadService);

    public readonly jobs = signal<CoreoJob[]>([]);
    private pollingSubscription: Subscription;

    private jobSubjects = new Map<CoreoJob['uuid'], Subject<CoreoJob>>();

    private readonly jobTitles: Record<CoreoJobType, string> = {
        'exportProjectUsers': 'Export Project Users',
        'exportOrganisationUsers': 'Export Organisation Users',
        'exportCollectionCSV': 'Export Collection',
        'exportRecords': 'Export Records',
        'exportRecordsMedia': 'Export Records Media',
        'exportRecordsLegacy': 'Export Records',
        'exportRecordLogsCSV': 'Export Record Logs',
        'cloneProject': 'Clone Project',
        'importRecords': 'Import Records',
        'importShapefileCollection': 'Import Collection',
        'importGeoJSONCollection': 'Import Collection',
        'importCSVCollection': 'Import Collection Items',
        'importBoundaries': 'Import Boundaries'
    };

    private readonly jobActions: Partial<Record<CoreoJobType, string>> = {
        'cloneProject': 'Open Project',
        'exportProjectUsers': 'Download Project Users Export',
        'exportOrganisationUsers': 'Download Organisation Users Export',
        'exportCollectionCSV': 'Download Collection Export',
        'exportRecords': 'Download Records Export',
        'exportRecordsMedia': 'Download Records Media Export',
        'exportRecordsLegacy': 'Download Records Export',
        'exportRecordLogsCSV': 'Download Record Logs Export',
        'importShapefileCollection': 'Go to collection',
        'importGeoJSONCollection': 'Go to collection',
        'importCSVCollection': 'Go to collection'
    };

    activeJobs = computed(() => {
        return this.jobs().filter(j => j && (j.status === 'pending' || j.status === 'processing'));
    });

    constructor() {
        effect(() => {
            const jobs = this.activeJobs();
            if (jobs.length > 0) {
                if (!this.pollingSubscription || this.pollingSubscription.closed) {
                    this.startPolling();
                }
            } else {
                this.stopPolling();
            }

        });
    }

    addJob(job: CoreoJob) {
        if (!job) {
            return;
        }

        this.jobs.update(a => [...a, job]);
        const subject = new Subject<CoreoJob>();
        this.jobSubjects.set(job.uuid, subject);
        subject.next(job);
        return subject;
    }

    public async actionJob(job: CoreoJob) {
        switch (job.type) {
            case 'exportCollectionCSV':
            case 'exportProjectUsers':
            case 'exportOrganisationUsers':
            case 'exportRecordsLegacy': {
                window.open(job.url, '_blank');
                break;
            }
            case 'exportRecords':
            case 'exportRecordsMedia': {
                const url = await this.downloadService.exportFiles(job.url);
                window.open(url, '_blank');
                break;
            }
            case 'importCSVCollection':
            case 'importShapefileCollection':
            case 'importGeoJSONCollection': {
                this.openCollection(parseInt(job.options.projectId), parseInt(job.url));
                break;
            }
            case 'cloneProject': {
                this.openProject(parseInt(job.url));
                this.clear(job);
                break;
            }
        }
    }

    init() {
        if (this.userService.getCurrentUser()?.id) {
            this.loadJobs();
        }
    }

    public jobTitle(job: CoreoJob): string {
        switch (job.type) {
            default: {
                return this.jobTitles[job.type] ?? 'Unknown';
            }
        }
    }

    public jobAction(job: CoreoJob): string {
        return this.jobActions[job.type] ?? '';
    }

    private loadJobs() {
        return this.getJobs().subscribe(jobs => this.updateJobs(jobs));
    }

    private updateJobs(jobs: CoreoJob[]) {
        const curentJobs = this.jobs();
        const removedJobs = curentJobs.filter(j => !jobs.find(j2 => j2.uuid === j.uuid));

        this.jobs.set(jobs);
        for (const j of [...jobs, ...removedJobs]) {
            const subject = this.jobSubjects.get(j.uuid);
            if (subject) {
                subject.next(j);
                if (removedJobs.includes(j) || j.status === 'complete' || j.status === 'failed') {
                    subject.complete();
                    this.jobSubjects.delete(j.uuid);
                }
            }
        }
    }

    getJobs() {
        return this.apiService.graphql(jobsQuery).pipe(map((response: { viewer: { jobs: CoreoJob[] } }) => response.viewer.jobs));
    }

    clear(job: CoreoJob) {
        const mutation = `mutation CoreoAADeleteJob($uuid: String!){
            deleteJob(uuid: $uuid)
        }`;
        this.apiService.graphql(mutation, { uuid: job.uuid }).subscribe(() => {
            this.jobs.update(jobs => jobs.filter(j => j.uuid !== job.uuid));
        });
    }

    flush() {
        this.jobs.set([]);
        this.jobSubjects.clear();
    }

    private startPolling(): void {
        console.log('Starting Polling');
        const pollingInterval = 5000;
        this.pollingSubscription = interval(pollingInterval).pipe(
            switchMap(() => this.getJobs().pipe(
                catchError(error => {
                    console.error('Poll error', error);
                    return of([]);
                })
            )
            )).subscribe(response => {
                this.updateJobs(response);
            });
    }

    private stopPolling(): void {
        if (this.pollingSubscription) {
            this.pollingSubscription.unsubscribe();
            this.pollingSubscription = undefined;
            console.log('Stopped polling');
        }
    }

    private loadProject(id: number) {
        const query = `query CoreoAAGetProject($id: Int!){
            project(id: $id){
                slug
                organisation{
                slug
            }
        }
    }`;
        return this.apiService.graphql<JobsLoadProjectResponse>(query, { id });
    }

    private openCollection(projectId: number, collectionId: number) {
        return this.loadProject(projectId).subscribe((res) => {
            const url = `/${res.project.organisation.slug}/${res.project.slug}/collections/${collectionId}`;
            window.open(url, '_self');
        });
    }


    private openProject(id: number) {
        return this.loadProject(id).subscribe((res) => {
            const url = `/${res.project.organisation.slug}/${res.project.slug}`;
            window.open(url, '_self');
        });
    }
}