import { Component, computed, ElementRef, EventEmitter, inject, input, OnDestroy, OnInit, Output, signal, viewChild, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import mapboxgl, { FullscreenControl, LngLatBoundsLike, LngLatLike, Map as MapboxMap } from "mapbox-gl";
import bbox from '@turf/bbox';
import { addBoundsLayer, addFeatureLayers, moveRecordsLayer } from '../../records.map.utils';
import { Feature, Geometry, MultiPolygon, Point, Polygon } from 'geojson';
import MapboxGIS from '@natural-apptitude/coreo-mapbox';
import { CoreoGeometryType, MapboxGISBaseMode } from '@natural-apptitude/coreo-mapbox/dist/types';
import { baseStyles, MAP_MENU_LAYERS_CHANGED_EVENT, MAP_MENU_STYLE_CHANGED_EVENT, MapsService } from 'src/app/core/services/maps.service';

export type ConfigGeometryTypes = 'polygon' | 'linestring' | 'point' | 'multipoint' | 'multilinestring' | 'multipolygon';

const geometryTypeMap = {
    polygon: 'Polygon',
    linestring: 'LineString',
    point: 'Point',
    multipoint: 'MultiPoint',
    multilinestring: 'MultiLineString',
    multipolygon: 'MultiPolygon'
}

@Component({
    selector: 'app-record-map',
    templateUrl: 'record-map.component.html',
    standalone: true
})

export class RecordMapComponent implements OnInit, OnDestroy {

    viewContainerRef = inject<ViewContainerRef>(ViewContainerRef);
    mapsService = inject(MapsService);

    baseStyleName = input();
    feature = input<Feature>();
    showFullscreen = input<boolean>(true);
    edit = input<boolean>(false);
    allowedGeometryTypes = input<ConfigGeometryTypes[]>(); // only used when in edit mode
    enforceBounds = input<boolean>(false); // only used when in edit mode
    projectBounds = input<any>(); // only used when in edit mode
    replace = input<{ op: string; path: string; value: number }[]>(); // only used in the review changes modal

    @Output() onStyleChanged: EventEmitter<string> = new EventEmitter();

    mapDiv = viewChild<ElementRef>('recordDetailMap');

    touched = signal<boolean>(false); // Whether the GIS tools have been used
    updatedGeometry = signal<Geometry | null>(null);

    baseStyle = computed(() => {
        const name = this.baseStyleName();
        const mapboxName = name ?? 'Mapbox Streets'
        return baseStyles.find(s => s.mapboxName === mapboxName).url;
    });

    private map: MapboxMap;
    private mapboxGIS: MapboxGIS;
    private resizeObserver: ResizeObserver;

    ngOnInit() {
        this.initMap();
    }

    ngOnDestroy(): void {
        this.resizeObserver?.disconnect();
    }

    private initMap() {
        const mapOptions: mapboxgl.MapboxOptions = {
            accessToken: environment.mapboxApiKey,
            container: this.mapDiv().nativeElement,
            style: this.baseStyle(),
            projection: { name: 'mercator' },
        };

        if (this.feature()) {
            if (this.feature().geometry.type === "Point") {
                mapOptions.center = (this.feature().geometry as Point).coordinates as LngLatLike;
                mapOptions.zoom = 16;
            } else {
                const bounds = bbox(this.feature().geometry);
                mapOptions.bounds = bounds as LngLatBoundsLike;
                mapOptions.fitBoundsOptions = { padding: 20 };
            }
        } else {
            const defaultBounds = [
                2.0153808593734936, 56.6877748258257,
                -7.0153808593762506, 47.45206245445874
            ];
            mapOptions.bounds = defaultBounds as LngLatBoundsLike;
            mapOptions.fitBoundsOptions = { padding: 20 };
        }

        this.map = new MapboxMap(mapOptions);

        this.map.on('load', () => {

            this.mapsService.attachMapMenuControl(this.map, this.viewContainerRef, {
                position: 'top-left'
            });
            if (this.showFullscreen()) {
                this.map.addControl(new FullscreenControl());
            }
    
            if (this.edit()) {
                this.initGIS();
            } else {
                addFeatureLayers(this.map, [this.feature()]);
            }
    
            this.map.on(MAP_MENU_STYLE_CHANGED_EVENT, (e: any) => {
                /** Emit style name so we can keep track when switching between summary and edit */
                const styleName: string = (e.target as MapboxMap).getStyle().name;
                this.onStyleChanged.emit(styleName);

                if (this.edit()) {
                    if (this.map.hasControl(this.mapboxGIS)) {
                        this.map.removeControl(this.mapboxGIS);
                    }
                    this.map.addControl(this.mapboxGIS);
                    
                } else {
                    addFeatureLayers(this.map, [this.feature()]);
                }
            });
    
            this.map.on(MAP_MENU_LAYERS_CHANGED_EVENT, () => {
                if (this.edit() && this.map.hasControl(this.mapboxGIS)) {
                    this.mapboxGIS.moveToTop();
                } else {
                    moveRecordsLayer(this.map);
                }
            });
    
            this.resizeObserver = new ResizeObserver(() => this.map.resize());
            this.resizeObserver.observe(this.mapDiv().nativeElement);
        });

    }

    private initGIS() {
        if (this.edit() && this.enforceBounds() && this.projectBounds()) {
            addBoundsLayer(this.map, this.projectBounds());
        }

        const allowedGeometryTypes = this.allowedGeometryTypes().map(type => geometryTypeMap[type] as CoreoGeometryType);
        
        let geometryType: CoreoGeometryType = 'Point';
        let mode: MapboxGISBaseMode = 'create';
        
        if (this.feature()) {
            geometryType = this.feature().geometry.type as CoreoGeometryType;
            mode = 'update';
        } else if (allowedGeometryTypes.length > 0) {
            geometryType = allowedGeometryTypes[0];
        }

        import('@natural-apptitude/coreo-mapbox').then(({ default: MapboxGIS }) => {
            this.mapboxGIS = new MapboxGIS({
                geometryType,
                feature: this.feature(),
                bounds: (this.enforceBounds() && !!this.projectBounds()) ? this.projectBounds() as (Polygon | MultiPolygon | Polygon[]) : null,
                modes: ['create', 'update', 'delete'],
                mode,
                allowedGeometryTypes,
                typeControl: true,
                toolbarControl: false,
                interface: 'desktop',
                logging: false
            });

            this.mapboxGIS.on('geometry', (e) => {
                // console.log('mapboxGIS', e);
                this.touched.set(true);
                this.updatedGeometry.set(e?.geometry ?? null);
            });

            this.map.addControl(this.mapboxGIS);
        });
    }

    /** This isn't being used at the moment will need refactoring if we add review changes back in */
    /** Use a separate replacedFeature signal rather than updating the input feature */
    // private replaceCoordinates() {
    //     const { type } = this.feature().geometry;
    //     /** Have to add a check in order to access the coordinates property */
    //     if (
    //         type === 'Point' ||
    //         type === 'MultiPoint' ||
    //         type === 'LineString' ||
    //         type === 'MultiLineString' ||
    //         type === 'Polygon' ||
    //         type === 'MultiPolygon'
    //     ) {
    //         const coords = [...this.feature().geometry.coordinates];

    //         const replacementData: { indexes: number[], value: number }[] = this.replace().map(r => {
    //             const indexes: number[] = r.path.replaceAll('geometry', '')
    //                 .replaceAll('coordinates', '')
    //                 .replaceAll('/', '')
    //                 .split('')
    //                 .map(i => +i);

    //             const value = r.value;

    //             return {
    //                 indexes,
    //                 value
    //             }
    //         });

    //         function setNewValue(arr, indexes, value) {
    //             indexes.reduce((r, e, i, a) => {
    //                 const last = i === a.length - 1;

    //                 if (last) {
    //                     if (Array.isArray(r)) {
    //                         r[e] = value
    //                     }
    //                 }

    //                 return r[e]
    //             }, arr);
    //         }

    //         replacementData.forEach((r) => {
    //             setNewValue(coords, r.indexes, r.value);
    //         });

    //         this.feature.set({
    //             ...this.feature(),
    //             geometry: {
    //                 ...this.feature().geometry,
    //                 coordinates: coords as any
    //             }
    //         });
    //     }
    // }
}