import * as _ from 'lodash';
import CoreoAPIService from '../../main/coreoApi';

interface CreateProjectMapInput {
    name: string;
    description: string;
}

export default class ProjectMapsService {

    mapString = 'id,uuid,name,description,shared,style,base,zoom,center{coordinates},layers{id,name,type,sourceType,sourceId,layout,paint';

    static $inject = ['CoreoAPI'];
    constructor(private CoreoAPI: CoreoAPIService) { }

    getMaps(projectId: number) {
        var query = `{project(id: ${projectId}){maps{${this.mapString}}}}}`;
        return this.CoreoAPI.query(query).then(function (data) {
            return data.project.maps;
        });
    }

    getMap(projectId: number, mapId: number, base: string) {
        var query = `{project(id: ${projectId}){map(id: ${mapId}, ${base ? `base: "${base}"` : ''}){id,uuid,description,shared,name,style,base,zoom,center{coordinates},layers{id,name,type,sourceType,sourceId,layout,paint}}}}`;
        return this.CoreoAPI.query(query).then(data => {
            return data && data.project && data.project.map;
        });
    }

    getDefaultMap(projectId, base) {
        const mapQuery = `map${base ? `(base: "${base}")` : ''}{${this.mapString}}}`;
        var query = '{project(id: ' + projectId + '){' + mapQuery + '}}';
        return this.CoreoAPI.query(query).then(function (data) {
            const map = data.project && data.project.map || {};
            map.id = 'default';
            return map;
        });
    }

    updateMap(id, data, variables) {
        var update = this.CoreoAPI.gqlStringify(_.assign({}, { id: id }, data));
        var mutation = 'mutation{result: updateProjectMap(input: ' + update + '){id}}';
        return this.CoreoAPI.mutation(mutation, null, { variables: variables });
    }

    createMap(input: CreateProjectMapInput) {
        return this.CoreoAPI.gql<any>(`mutation AACreateMap($input: ProjectMapCreateInput!){
            map: createProjectMap(input: $input){
                id,
                name,
                style,
                base,
                layers{
                    id
                }
            }
        }`, { input }).then(result => result.map);
    }

    createMapLayer(data) {

        const input = {
            name: data.name,
            type: data.type,
            sourceType: data.sourceType,
            sourceId: data.sourceId,
            projectMapId: data.projectMapId
        };

        // var update = this.CoreoAPI.gqlStringify(u);
        // update = update.substring(0, update.length - 1) + ',paint: ' + JSON.stringify(JSON.stringify(data.paint || {})) + ', layout: ' + JSON.stringify(JSON.stringify(data.layout || {})) + '}';
        // var mutation = 'mutation{result: createProjectMapLayer(input: ' + update + '){id,name,sort,sourceType,sourceId,layout,paint}}';

        const mutation = `mutation($input: ProjectMapLayerCreateInput!){ 
            result: createProjectMapLayer(input: $input){
                id,
                name,
                source,
                sourceType,
                sourceId,
                layout,
                paint
            }
        }`;
        return this.CoreoAPI.gql(mutation, { input });
        // return this.CoreoAPI.mutation(mutation);
    }

    updateMapLayer(id, data) {
        var u = {
            id: id,
            name: data.name,
            type: data.type,
            sourceType: data.sourceType,
            sourceId: data.sourceId,
            source: data.source,
            sort: data.sort
        };

        var update = this.CoreoAPI.gqlStringify(u);
        if (data.paint || data.layout) {
            update = update.substring(0, update.length - 1) + ',paint: ' + JSON.stringify(JSON.stringify(data.paint || {})) + ', layout: ' + JSON.stringify(JSON.stringify(data.layout || {})) + '}';
        }
        var mutation = 'mutation{result: updateProjectMapLayer(input: ' + update + '){id}}';
        return this.CoreoAPI.mutation(mutation);
    }

    updateMapPosition(id, zoom, lat, lng) {
        var mutation = 'mutation{result: updateProjectMap(input: {id: ' + id + ',center: { type: Point, crs: { type: "name", properties:{name:"EPSG:4326"} }, coordinates: [' + lng + ',' + lat + ']}, zoom: ' + zoom + '}){id}}';
        return this.CoreoAPI.mutation(mutation);
    }

    deleteMap(id: number) {
        return this.CoreoAPI.gql(`mutation AADeleteProjectMap($id: Int!){
            deleteProjectMap(input: {id: $id})
        }`, {
            id
        });
    }

    deleteMapLayer(id) {
        var mutation = 'mutation{result: deleteProjectMapLayer(input:{id:' + id + '})}';
        return this.CoreoAPI.mutation(mutation);
    }

    async uploadGeoTIFF(projectId: number, file: File): Promise<{ url: string; bounds: number[][] }> {
        const query = `query AAUploadGeoTIFF($projectId: Int!){
            processGeoTIFF(projectId: $projectId){
                url,
                bounds{
                    coordinates
                }
            }
        }`;
        const variables = {
            projectId
        };

        const result = await this.CoreoAPI.gqlWithFiles(query, { geotiff: file }, variables);
        const { url, bounds } = result.processGeoTIFF;
        return {
            url,
            bounds: bounds.coordinates
        };
    }

    async uploadShapefile(projectId: number, file: File): Promise<{ url: string; bounds: number[] }> {
        const query = `query AAUploadShapefile($projectId: Int!){
            processShapefile(projectId: $projectId){
                url,
                bounds
            }
        }`;
        const variables = {
            projectId
        };

        const result = await this.CoreoAPI.gqlWithFiles(query, { geotiff: file }, variables);
        const { url, bounds } = result.processShapefile;
        console.log('HERE', result);
        return {
            url,
            bounds: bounds
        };
    }

}
ProjectMapsService.$inject = ['CoreoAPI'];